



import React, { useEffect, useRef, useState } from 'react'
import Sidebar from '../../common/sidebar/sidebar'
import { Header } from '../../common/header/header'
import rightGraphImage from '../../../assets/images/graph-right.png'
import userImage from '../../../assets/images/user.svg'
import upArrowImage from '../../../assets/images/up.svg'

import downarrow from '../../../assets/images/down.svg';

import leftGraphImage from '../../../assets/images/graph-left.png'
import propertiesimg from '../../../assets/images/properties.svg'
import sellersimg from '../../../assets/images/sellers.svg'
import buyerimg from '../../../assets/images/Buyers.svg'
import { Chart } from 'primereact/chart';
import apiService from '../../../services/apiservice'
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import useAuthentication from '../../../utils/authHook'
  

const Dashboard = () => {
  useAuthentication()
  const [lineChartData, setLineChartData] = useState({});
  const [doughnutChartData, setDoughnutChartData] = useState({});

  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const sidebarRef = useRef(null);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setIsSidebarOpen(false);
      }
    };

    const handleDashboardClick = () => {
      if(isSidebarOpen){
        setIsSidebarOpen(!isSidebarOpen);
      }
    };

    useEffect(() => {
      document.addEventListener('click', handleClickOutside);

      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }, []);

  const fetchData = async () => {
    try {
      const data = await apiService.get_dashboard_data(); 
      return (data||{});
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
    }
  };


  const query = useQuery({ queryKey: ['dashboard'], queryFn: fetchData, })
    
  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
    const linedata = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
            {
                label: 'Offline',
                data: [65, 59, 80, 81, 56, 55, 40],
                fill: false,
                borderColor: documentStyle.getPropertyValue('--yellow-color'),
                tension: 0.4
            },
            {
                label: 'Online',
                data: [28, 48, 40, 19, 86, 27, 90],
                fill: false,
                borderColor: documentStyle.getPropertyValue('--blue-color'),
                tension: 0.4
            }
        ]
    };
    const options = {
        maintainAspectRatio: false,
        aspectRatio: 1,
        plugins: {
            legend: {
                labels: {
                    color: textColor
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: textColorSecondary
                },
                grid: {
                    color: surfaceBorder
                }
            },
            y: {
                ticks: {
                    color: textColorSecondary
                },
                grid: {
                    color: surfaceBorder
                }
            }
        }
    };

    setLineChartData(linedata);
    setChartOptions(options);
}, []);

useEffect(() => {
  const doughnutData = {
    datasets: [
      {
        data: [70, 30 ],
        backgroundColor: ['#2E92D9', '#E6EDFF', '#E6EDFF'],
        hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
      },
      {
        data: [60,  40],
        backgroundColor: ['#DE227A', '#E6EDFF', '#E6EDFF'],
        hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
      },
      {
        data: [50,  20],
        backgroundColor: ['#004BCE', '#E6EDFF', '#E6EDFF'],
        hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
      },
    ],
  };
  setDoughnutChartData(doughnutData);

}, []);

  return (
    <div onClick={handleDashboardClick} className='dashboard-inner-main'>
    <div className="flex flex-col " >
      <Header toggleSidebar={toggleSidebar} />
      <div className=" flex-grow">

      <Sidebar isSidebarOpen={isSidebarOpen} />


      <div className="flex flex-col flex-grow overflow-auto"> {/* Ensure proper overflow */}
       {/* <div className="RightSideDiv pl-80 pr-5"> */}
               <div className="right-side-div pl-5 pt-4 pr-5 md:pl-5 lg:pl-72">

               <div className="dash-box bg-white border border-blue-200 rounded-lg p-4 mb-4 flex flex-wrap md:flex-nowrap items-center justify-between">

         {/* <div className="dashboard-top bg-white border border-blue-200 rounded-lg p-6 mb-8 flex items-center justify-between  "> */}
  
    
      <div className="dash-box relative border-r border-blue-200   w-1/4 ">
        <div className="dash-text  ">
          <h2 className="text-4xl font-bold">{query?.data?.users}</h2>
          <h3 className="text-xl font-normal sm:text-sm lg:text-base mb-2">Total users</h3>
          <span className="absolute top-0 right-8 bg-white shadow-lg border border-blue-200 rounded-full sm:h-10 sm:w-10 lg:w-10 lg:h-10 flex items-center justify-center">
          <img src={userImage} alt="" className="w-6 h-6" />
          </span>
          <h4 className="text-xs font-normal text-blue-700"><img src={upArrowImage} alt="" className="inline-block mr-1" /> { query?.data?.userPercentageIncrease}%this week</h4>
        </div>
      </div>
    
      <div className="dash-box relative border-r border-blue-200 w-1/4 pl-4">
        <div className="dash-text">
          <h2 className="text-4xl font-bold">{query?.data?.properties}</h2>
          <h3 className="text-xl font-normal mb-2 sm:text-sm lg:text-base " >Total Properties</h3>
          <span className="absolute top-0 right-8 bg-white shadow-lg border border-blue-200 rounded-full sm:h-10 sm:w-10 lg:w-10 lg:h-10 flex items-center justify-center">
            <img src={propertiesimg} alt="" className="w-6 h-6" />
          </span>
          <h4 className="text-xs font-normal text-blue-700"><img src={upArrowImage} alt="" className="inline-block mr-1" /> {query?.data?.propertiesPercentageIncrease}% this week</h4>
        </div>
      </div>
   
   
      <div className="dash-box relative border-r border-blue-200 w-1/4  pl-4">
        <div className="dash-text">
          <h2 className="text-4xl font-bold">{query?.data?.buyers}</h2>
          <h3 className="text-xl font-normal mb-2 sm:text-sm lg:text-base ">Total Buyers</h3>
          <span className="absolute top-0 right-8 bg-white shadow-lg border border-blue-200 rounded-full sm:h-10 sm:w-10 lg:w-10 lg:h-10 flex items-center justify-center">
            <img src={buyerimg} alt="" className="w-6 h-6" />
          </span>
          <h4 className="text-xs font-normal text-blue-700"><img src={downarrow} alt="" className="inline-block mr-1" />{query?.data?.buyersPercentageIncrease}% this week</h4>
        </div>
      </div>
    
   
      <div className="dash-box relative w-1/4 pl-4">
        <div className="dash-text">
          <h2 className="text-4xl font-bold">{query?.data?.sellers}</h2>
          <h3 className="text-xl font-normal mb-2 sm:text-sm lg:text-base">Total Sellers</h3>
          <span className="absolute top-0 right-8 bg-white shadow-lg border border-blue-200 rounded-full sm:h-10 sm:w-10 lg:w-10 lg:h-10 flex items-center justify-center">
  <img src={sellersimg} alt="" className="w-6 h-6" />
</span>

          <h4 className="text-xs font-normal text-blue-700"><img src={upArrowImage} alt="" className="inline-block mr-1" />{query?.data?.sellersPercentageIncrease}% this week</h4>
        </div>
      </div>
   
</div>

<div className="row flex">
  <div className="col-sm-8 flex-grow">
    <div className="dash-graph bg-white border border-blue-200 rounded-lg p-6 ">
    <h3 className='font-bold'>Total Revenue</h3>

      <Chart type="line" data={lineChartData} options={chartOptions} />

    </div>
  </div>

  <div className="col-sm-4">
    <div className="dash-graph bg-white border border-blue-200 rounded-lg p-6">
     
       <div>
      <h3 className='font-bold'>Earnings</h3>
      <Chart type="doughnut" data={doughnutChartData} />
    </div>
    </div>
  </div>
</div>


       </div>
     </div>
     </div>
   </div>
   </div>  )
}

export default Dashboard