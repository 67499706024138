// AuthService.js
import config from "../../config";

const BASE_URL = `${config.baseURL}/auth` ; 

const AuthService = {
  login: async (email, password) => {
    try {
      const response = await fetch(`${BASE_URL}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          password,
        }),
      });

      if (!response.ok) {
        // Extract error message from response
        const errorData = await response.json();
        console.log('this is error', errorData);
        // the below is appropriate method to show error message from backend api which is commented
        const errorMessage = errorData.message || 'Invalid credentials';

      //  const errorMessage = 'The email/password is wrong';
        throw new Error(errorMessage);
      }

      const data = await response.json();
      // Handle the response data (e.g., store tokens, update state)
      console.log('Login successful:', data);

      return data;
    } catch (error) {
      console.error('Login failed:', error.message);
      throw error;
    }
  },


  forgotPassword: async (email, ) => {
    try {
      const response = await fetch(`${BASE_URL}/forgotPassword`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', // Specify the content type as JSON
        },
        body: JSON.stringify(
           email, // Wrap the email in an object
        ),
      });
  
      if (!response.ok) {
        // Extract error message from response
        const errorData = await response.json();
        console.log('this is error', errorData);
        
        // Use the appropriate method to handle the error message from the backend API
        const errorMessage = errorData.message || 'Invalid credentials';
  
        throw new Error(errorMessage);
      }
  
      const data = await response.json();
      // Handle the response data (e.g., store tokens, update state)
      console.log('Forgot password request successful:', data);
  
      return data;
    } catch (error) {
      console.error('Forgot password request failed:', error.message);
      throw error;
    }
  },


  
};

export default AuthService;
