



  import React, { useState, useEffect, useRef } from 'react';
  import editicon from '../../../assets/images/edit_icon.svg';
  import Deleteicon from '../../../assets/images/delete_icon.svg';
  import { Header } from '../../common/header/header';
  import Sidebar from '../../common/sidebar/sidebar';
  import profilepic from '../../../assets/images/placeholder-image-person-jpg.jpg';
  import apiService from '../../../services/apiservice';
  import Modal from '../../common/modal/modal';
  import { ManageUsersModal } from '../../common/modal/manageusers-Modal/manageusersModal';
  import prev_button from '../../../assets/images/prevbutton.svg';
  import next_button from '../../../assets/images/nextbutton.svg';

  import {
    useQuery,
    useMutatiosn,
    useQueryClient,
    QueryClient,
    QueryClientProvider,
  } from '@tanstack/react-query'
  import { toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  import Loader from '../../common/loader/loader';
  import { useActionData } from 'react-router-dom';
  import useAuthentication from '../../../utils/authHook';
import { DeleteModal } from '../../common/modal/deleteModal/deleteModal';



  const ManageUsers = () => {
  useAuthentication()
    //-------------------------------------------------- variable definitions start-------------------------------------------------------------
    const [userData, setUserData] = useState([]);
    const itemsPerPage = 5; 
    const [modalAddUserOpen, setmodalAddUserOpen] = useState(false);
    const [modalEditUserOpen, setmodalEditUserOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(10);
    const [loading, setLoading] = useState(true); // New state variable for loading indicator

    //-------------------------------------------------- variable definitions ends-------------------------------------------------------------

    //-------------------------------------------------- fetch user STARTS-------------------------------------------------------------

  const fetchUsers = async () => {
        try {
          if(filterValue ===''){

            setLoading(true); 
          }

          const users = await apiService.getUsers(skip,take,filterValue,status);
          setTotalPages(Math.ceil(users.totalCount / take));
          setUserData(users);
          setnoData(false);
          return users
        } catch (error) {
          if (error.response && error.response.status === 400 && error.response.data.message === "No Data Found") {
            // Show toastr warning for no data found
      setnoData(true)        
          }

        }finally {
          setLoading(false); // Set loading to false once data is fetched
        }
      };

    const query = useQuery({ queryKey: ['manageuser'], queryFn: fetchUsers, })
    //-------------------------------------------------- fetch user ENDS -------------------------------------------------------------



    //-------------------------------------------------- Modal code STARTS -------------------------------------------------------------
    const queryClient = useQueryClient();


    const openAdduser = () => setmodalAddUserOpen(true);
    const closeAdduser = () => setmodalAddUserOpen(false)

    const openEdituser = () => setmodalEditUserOpen(true);
    const closeEdituser = () => setmodalEditUserOpen(false)

    const [selectedUserForEdit, setSelectedUserForEdit] = useState(null);
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const [selectedUserForDelete, setSelectedUserForDelete] = useState(null);
    const [filterValue, setFilterValue] = useState('');
    const [noData, setnoData] = useState(false)
    const [status, setStatus] = useState('');

    //-------------------------------------------------- Modal codeENDS -------------------------------------------------------------

      

    const openEditUser = (user) => {
      // Set other state or perform other actions related to editing
      setSelectedUserForEdit(user);

      setmodalEditUserOpen(true);
    };
    


    const openDeleteConfirmation = (user) => {
      setSelectedUserForDelete(user.id);
      setDeleteConfirmationOpen(true);
    };
    
    const closeDeleteConfirmation = () => {
      setSelectedUserForDelete(null);
      setDeleteConfirmationOpen(false);
    };
    


    const handlePageChange = (newPage) => {
      setCurrentPage(newPage);
    };

    // Calculate the start and end indices for the current page
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;





    const handleDeleteUser = async () => {
      try {
        
        const result = await apiService.deleteUser(selectedUserForDelete);
        if (result.statusCode === 200) {
          queryClient.invalidateQueries('manageuser');
          closeDeleteConfirmation();
          toast.success(result.message);
        } else {
          console.error('Error deleting user:', result);
            toast.error(result.message || 'Error deleting user');
        }
      } catch (error) {
        console.error('Unexpected error deleting user:', error);
        
        toast.error('Unexpected error deleting user');
      }
    };

  

    console.log('query',query)
    useEffect(() => {
      
      query.refetch();
    }, [skip,status,filterValue]);
    


    const Pagination = ({ currentPage, setCurrentPage, totalPages }) => {


      const goToPage = (page) => {
        console.log('page value111,',page, 'here si take val',take)
    
        setCurrentPage(page);
  
    console.log((page - 1) * take);
    setSkip((page - 1) * take);
    console.log('afterupdate,',skip, 'here si take val',take)
    query.refetch();
      };
    
      const handlePrevClick = () => {
        
        if (currentPage > 1) {
          goToPage(currentPage - 1);
        }
      };
    
      const handleNextClick = () => {
        if (currentPage < totalPages) {
          goToPage(currentPage + 1);
        }
      };
      const generatePageNumbers = () => {
        const pageNumbers = [];
        const maxPageButtons = 3; // Adjust this value to change the number of displayed pagess
        let startPage = Math.max(1, currentPage - Math.floor(maxPageButtons / 2));
        let endPage = Math.min(totalPages, startPage + maxPageButtons - 1);
        if (endPage - startPage + 1 < maxPageButtons) {
          startPage = Math.max(1, endPage - maxPageButtons + 1);
        }
        for (let i = startPage; i <= endPage; i++) {
          pageNumbers.push(i);
        }
        return pageNumbers;
      };
      return (
        <div className="flex items-center justify-center pt-15 pb-19 mt-5 mb-3 pagination">
          <ul className="flex list-none">
            <li
              className={`w-12 h-12 flex items-center justify-center cursor-pointer ${currentPage === 1 ? 'opacity-30 cursor-not-allowed':''}`}
              onClick={handlePrevClick}
            >
              <img src={prev_button} alt="Previous Page" />
            </li>
            {generatePageNumbers().map((page) => (
              <li
                key={page}
                className={`ml-2 w-12 h-12 border-2 border-solid border-gray-300 rounded-full text-gray-500 text-lg flex items-center justify-center cursor-pointer ${
                  page === currentPage ? 'bg-black shadow-md border-none text-white' : ''
                }`}
                onClick={() => goToPage(page)}
              >
                {page}
              </li>
            ))}
            <li
              className={`ml-2 w-12 h-12 flex items-center justify-center cursor-pointer ${currentPage === totalPages ?' opacity-30 cursor-not-allowed':" " }   `}
              onClick={handleNextClick}
            >
              <img src={next_button} alt="Next Page" />
            </li>
          </ul>
        </div>
      );
    };
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleSidebar = () => {
      setIsSidebarOpen(!isSidebarOpen);
    };


  

    const handleDashboardClick = () => {
      if(isSidebarOpen){
        setIsSidebarOpen(!isSidebarOpen);
      }
    };

  



    return (
      <div className="flex flex-col">
      <Header toggleSidebar={toggleSidebar} />
      <div className="flex flex-grow">
        <Sidebar isSidebarOpen={isSidebarOpen} />
        <div className="flex flex-col flex-grow overflow-auto">
        
        {modalAddUserOpen && (
    <Modal
      isOpen={modalAddUserOpen}
      onClose={closeAdduser}
      title="Add User"
      content={<ManageUsersModal  type={'add'} onClose={closeAdduser} />} // Adjust content as needed
    />
    
  )}

  <Modal
      isOpen={modalEditUserOpen}
      onClose={closeEdituser}
      title="Edit User"
      content={<ManageUsersModal  type={'edit'} userData={selectedUserForEdit} onClose={closeEdituser} />} // Adjust content as needed
    />

  <Modal
    isOpen={deleteConfirmationOpen}
    onClose={closeDeleteConfirmation}
    title="Delete User"
    content={
      <DeleteModal  type={'Manage-users'} Data={selectedUserForDelete} onClose={closeDeleteConfirmation}  />
    }
  />


  {loading ? (
        <Loader />
      ) : (

        <div className="HomeMainDiv   "   onClick={handleDashboardClick} >
          {/* <div className="RightSideDiv pl-80 pr-5"> */}
            <div className='pt-6 pl-5 lg:pl-72 pr-5'>
            <div className="right-side-inner ">
              <div className="user-top mb-6">
              
                <div className="flex items-center space-x-4">
                              <h3 className="text-xl font-semibold inline-block w-12 whitespace-nowrap">Manage Users</h3>
                              

                <div className="flex-grow"></div> {/* To push the search bar and "Add User" to the right */}


                <div className="post-select float-right ">
        {/* filter code is commented below */}
        
      <select className="post-select-option bg-white border border-blue-200 rounded-md w-28 h-10 px-4 styled-select"
      value={status}
    onChange={(e) => setStatus(e.target.value)}
    >
                <option value=''>All</option>
                <option value="Pending">Active</option>
                <option value="Closed">Inactive</option>
              </select>
      </div>

                      {/* <input
                        type="text"
                        className="border border-gray-200 rounded-lg p-2 w-45  "
                        placeholder="Search User"
                        onChange={(e) => setFilterValue(e.target.value)}
                      /> */}


                      <div className="relative">
  <input
    type="text"
    id="search"
    className="block h-10 px-2.5 pb-2.5 pt-4 w-45 text-sm text-gray-900 bg-transparent rounded-lg border-2 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
    placeholder=" "
    onChange={(e) => setFilterValue(e.target.value)}
  />
  <label
    htmlFor="search"
    className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-customBg px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-2"
  >
    Search User
  </label>
</div>

                      
                      <button
                        className="bg-black text-white text-sm py-2 h-10 w-[100px] mobile:w-[100px] mobile rounded-md"
                        onClick={openAdduser}
                      >
                        Add User
                      </button>
                    </div>
              </div>
              <div className="table-main bg-white rounded-lg overflow-y-auto mobile:max-h-[calc(100vh-350px)]  max-h-[calc(100vh-275px)] " >
                <table className="table w-full ">
                  <thead className="text-left">
                  
                    <tr>
                      <th scope="col" className='p-4 font-medium'>Image</th>
                      <th scope="col" className='p-4 font-medium'>Name</th>
                      <th scope="col" className='p-2 font-medium'>Email</th>
                      <th scope="col" className='p-4 font-medium'>Contact</th>
                      <th scope="col" className='p-4 font-medium'>Status</th>

                      <th scope="col" className='p-4 font-medium'>Action</th>
                    </tr>
                  </thead>
                  <tbody>

                  {noData ? (
            <tr>
              <td colSpan="5" className="text-center py-4 text-gray-500">No Data Found</td>
            </tr>
          ) : (
                  query?.data?.user?.map((user, index) => (
                        <tr key={user.id} className={` ${index % 2 === 0 ? 'bg-custom-gray' : ''}`}>
                        <td className="p-4 text-sm" >
                          <span className="user-img inline-block w-10 h-10 rounded-full overflow-hidden ">
                            <img src={user?.imageProfile || profilepic} alt="" className="w-full h-full object-cover"  />
                          </span>
                        </td>
                        <td className="p-4 text-sm">{`${user.firstName||'kevin'} ${user.lastName ||'jensen'}` }</td>
                        <td className="p-2 mail text-sm">{user.email || 'example@gmail.com'}</td>
                        <td className="p-2 text-sm">{user.phoneNumber||'+ 1 234 456 798 '}</td>
                        <td className="p-2 text-sm">{user?.reported[0]?.adminAction||'Active'}</td>
                        <td className="p-2 text-sm flex items-center mt-4">
                        {/* <td className="p-4 text-sm"> */}
                        <button className="w-8 h-8 bg-black text-white rounded-full" onClick={() => openEditUser(user)}>
                            <img src={editicon} alt="Edit Icon" />
                          </button>
                          <button className="w-8 h-8 bg-red-500 text-white rounded-full ml-2" onClick={() =>openDeleteConfirmation(user)}>
                            <img src={Deleteicon} alt="Delete Icon" />
                          </button>
                        </td>
                      </tr>
      ))
      )}
                  </tbody>
                </table>
              </div>
              <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages} />
            </div>
          </div>
        </div>
        )}
  </div>
  </div>
  </div>
    );
  };

  export default ManageUsers;
