import React, { useContext, useEffect, useRef, useState } from 'react'
import { Header } from '../../common/header/header'
import Sidebar from '../../common/sidebar/sidebar'
import user_img from '../../../assets/images/placeholder-image-person-jpg.jpg'
import view_icon from '../../../assets/images/view (2).svg'
import delete_icon from '../../../assets/images/delete_icon.svg'
import apiService from '../../../services/apiservice'
import {  useNavigate, useSearchParams } from 'react-router-dom'
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import prev_button from '../../../assets/images/prevbutton.svg';
import next_button from '../../../assets/images/nextbutton.svg';
import { DeleteModal } from '../../common/modal/deleteModal/deleteModal'
import Modal from '../../common/modal/modal'
import Loader from '../../common/loader/loader'
import useAuthentication from '../../../utils/authHook'
import { formatDate } from '../../../utils/functions'
import FilterContext from '../../../context/filterContext'




const SupportRequests = () => {
  useAuthentication()
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(10);
   const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [selectedUserForDelete, setSelectedUserForDelete] = useState(null);
  const [loading, setLoading] = useState(true); // New state variable for loading indicator
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  // const [filterValue, setFilterValue] = useState('');
    const { filterValue, setFilterValue } = useContext(FilterContext);

  const [noData, setnoData] = useState(false)

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const openDeleteConfirmation = (user) => {
    setSelectedUserForDelete(user);
    setDeleteConfirmationOpen(true);
  };
  
  const closeDeleteConfirmation = () => {
    setSelectedUserForDelete(null);
    setDeleteConfirmationOpen(false);
  };
  
  const Navigate = useNavigate();

  const fetch_support_requests = async () => {
    try {
      setLoading(true); 

      const data = await apiService.get_support_requests(skip,take,filterValue);
      setTotalPages(Math.ceil(data.totalCount / take));
      // const totalpg=100
      // setTotalPages(totalpg);
      setnoData(false);

return data
    } catch (error) {
      if (error.response && error.response.status === 400 && error.response.data.message === "No Data Found") {
        // Show toastr warning for no data found
  setnoData(true)        
      }
      
    }finally{
      setLoading(false)
    }
  };


  const handleViewClick = (requestId) => {
    // Navigate to the other page with the request ID as a parameter
    Navigate(`/support-requests/support-request-view/${requestId}`);
    // Navigate(`/support-request-view`);

  };
  

  const handleDashboardClick = () => {
    if(isSidebarOpen){
      setIsSidebarOpen(!isSidebarOpen);
    }
  };
  



  const query = useQuery({ queryKey: ['SupportRequests'], queryFn: fetch_support_requests, })

  useEffect(() => {
    query.refetch();
  }, [skip,filterValue]);
  
  const Pagination = ({ currentPage, setCurrentPage, totalPages }) => {


    const goToPage = (page) => {
      console.log('page value111,',page, 'here si take val',take)
  
      setCurrentPage(page);
  
  console.log((page - 1) * take);
  setSkip((page - 1) * take);
  console.log('afterupdate,',skip, 'here si take val',take)
  query.refetch();
    };
  
    const handlePrevClick = () => {
      if (currentPage > 1) {
        goToPage(currentPage - 1);
      }
    };
  
    const handleNextClick = () => {
      if (currentPage < totalPages) {
        goToPage(currentPage + 1);
      }
    };
    const generatePageNumbers = () => {
      const pageNumbers = [];
      const maxPageButtons = 3; // Adjust this value to change the number of displayed pagess
      let startPage = Math.max(1, currentPage - Math.floor(maxPageButtons / 2));
      let endPage = Math.min(totalPages, startPage + maxPageButtons - 1);
      if (endPage - startPage + 1 < maxPageButtons) {
        startPage = Math.max(1, endPage - maxPageButtons + 1);
      }
      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }
      return pageNumbers;
    };
    
    return (
      <div className="flex items-center justify-center pt-15 pb-19 mt-5 mb-3 pagination ">
        <ul className="flex list-none">
        <li
          className={`w-12 h-12 flex items-center justify-center cursor-pointer ${
            currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          onClick={handlePrevClick}
        >
            <img src={prev_button} alt="Previous Page" />
          </li>
          {generatePageNumbers().map((page) => (
            <li 
              key={page}
              className={`ml-2 w-12 h-12 border-2 border-solid border-gray-300 rounded-full text-gray-500 text-lg flex items-center justify-center cursor-pointer ${
                page === currentPage ? 'bg-black shadow-md border-none text-white' : ''
              }`}
              onClick={() => goToPage(page)}
            >
              {page}
            </li>
          ))}
          <li
          className={`ml-2 w-12 h-12 flex items-center justify-center cursor-pointer ${
            currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          onClick={handleNextClick}
        >
            <img src={next_button} alt="Next Page" />
          </li>
        </ul>
      </div>
    );
  };


  return (
    <div className="flex flex-col">
    <Header toggleSidebar={toggleSidebar} />
    <div className="flex flex-grow">
      <Sidebar isSidebarOpen={isSidebarOpen} />
      <div className="flex flex-col flex-grow overflow-auto " >
    

      <Modal
  isOpen={deleteConfirmationOpen}
  onClose={closeDeleteConfirmation}
  title="Delete User"
  
  content={
    <DeleteModal  type={'support-request'}  Data={selectedUserForDelete} onClose={closeDeleteConfirmation} />
  }
/>

{loading ? (
      <Loader />
    ) : (

      <div className="HomeMainDiv  "   onClick={handleDashboardClick}  >
        {/* <div className="RightSideDiv pl-80 pr-5"> */}
        <div className='pt-6 pl-5 lg:pl-72 pr-5'>

        

          <div className="right-side-inner p-30 float-left w-full">
  <div className="user-top mb-15">
  <h3 className="text-xl font-semibold inline-block">Support Requests</h3>

    <div className="post-select float-right">
      {/* filter code is commented below */}
    <select className="post-select-option bg-white border border-blue-200 rounded-md w-40 h-10 mb-2 px-4 styled-select " 
    value={filterValue}
  onChange={(e) => setFilterValue(e.target.value)}
   >
              <option value="">All</option>
              <option value="Pending">Pending</option>
              <option value="Accepted">Accepted</option>
              <option value="Rejected">Rejected</option>

            </select>
    </div>
  </div>
  
  <div className="table-main bg-white border-radius-15 overflow-hidden block w-full    overflow-y-auto  mobile:mr-4   mobile:max-h-[calc(100vh-310px)] max-h-[calc(100vh-255px)]   " >
    <table className="table w-full">
      <thead className='text-left'>
        <tr>
          <th scope="col" className='p-4 font-medium'   >Image</th>
          <th scope="col" className='p-2 font-medium' >Property Name</th>
          <th scope="col" className='p-2 font-medium' >Date</th>
          <th scope="col" className='p-2 font-medium' >Description</th>
          <th scope="col" className='p-2 font-medium' >Status</th>
          <th scope="col" className='p-2 font-medium' >Action</th>
        </tr>
      </thead>
      <tbody>
      {noData ? (
          <tr>
            <td colSpan="5" className="text-center py-4 text-gray-500">No Data Found</td>
          </tr>
        ) : (

      query?.data?.request?.map((request,index) => (
                      <tr key={request.id} className={` ${index % 2 === 0 ? 'bg-custom-gray' : ''}`}>
                  

          <td className='p-4 flex items-center'>
  <span className="user-img inline-block w-10 h-10 bg-ddd border-radius-50 rounded-full overflow-hidden">
    <img src={request?.user?.imageProfile ||user_img } alt="" className="w-full h-full object-cover" />
  </span>
  <div className="user-info inline-block vertical-align-middle pl-4">
    <h3 className="text-15px text-sm ">{request.name || 'Rick Wright'}</h3>
    <h4 className="text-xs text-gray-500">{request.email || 'samantha@mail.com'}</h4>
  </div>
</td>


          <td className='p-2 text-sm ' >{  request.propertyname || 'The Hideaway Cottage'}</td>
          {/* <td className='p-2 text-sm '>{  request.date ||' March 25, 2021'}</td> */}
          <td className='p-2 text-sm '>{request.createdAt.split('T')[0]|| 'March 25, 2021'}</td>

          <td className="desc max-w-52 text-sm p-4  ">{  request.description||'Lorem  galley'}</td>
          <td className='p-2  text-sm'><button className="status border-radius-50 w-100px text-8E8EA1 bg-ededef font-weight-500">{ request.status ||'Pending'}</button></td>
          {/* <td className="p-4"> */}
          <td className="p-2 text-sm flex items-center pl-2">

            <button className="w-8 h-8 bg-2E92D9 text-white rounded-full ml-2"  onClick={() => handleViewClick(request.id)} >
              <img src={view_icon} alt="View Icon" />
            </button>
            <button className="w-8 h-8 bg-black text-white rounded-full ml-2" onClick={() => openDeleteConfirmation(request.id)} >
                  <img src={delete_icon} alt="Edit Icon" />
                </button>
           
          </td>
        </tr>

    )))}
      </tbody>
    </table>
  </div>
  <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages} />

</div>



          
        </div>
      </div>
    )}
    </div>
    </div>
    </div>
  )
}

export default SupportRequests