import React, { useState } from 'react'
import { Header } from '../../common/header/header'
import Sidebar from '../../common/sidebar/sidebar'
import Modal from '../../common/modal/modal'

import Dashboard from '../dashboard/dashboard'
import { ManageUsersModal } from '../../common/modal/manageusers-Modal/manageusersModal'
import Tabs from '../../common/tabs/tabs'
import useAuthentication from '../../../utils/authHook'

const ReportRequests = () => {
  useAuthentication()
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleDashboardClick = () => {
    if(isSidebarOpen){
      setIsSidebarOpen(!isSidebarOpen);
    }
  };

    return (
      <div className="flex flex-col  ">
      <Header toggleSidebar={toggleSidebar} />
      <div className=" flex flex-grow">
        <Sidebar isSidebarOpen={isSidebarOpen} />
        <div className="flex flex-col flex-grow overflow-auto ">
   
     <div className="HomeMainDiv report-request "   onClick={handleDashboardClick} >
       {/* <div className="RightSideDiv pl-80 pr-5"> */}
       <div className='pt-6 pl-5 lg:pl-72 pr-5  '>

         <div className="right-side-inner">
         <h3 className="text-xl font-semibold inline-block">Report Requests</h3>
          
<Tabs/>

        
           
         </div>
       </div>
     </div>
   
   </div>
   </div>
   </div>
  )
}

export default ReportRequests