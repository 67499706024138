import * as Yup from 'yup';
const customEmailFormat = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
;




// ---------------------------------------------------------LOGIN PAGE VALIDATION STARTS------------------------------------------------------------ 

export const loginValidationSchema = Yup.object({
    email: Yup.string()
      .matches(customEmailFormat,'Invalid email address')
      .required('Email is required'),
    password: Yup.string()
      .required('Password is required'),
  });
// ---------------------------------------------------------LOGIN PAGE VALIDATION ENDS------------------------------------------------------------ 



// ---------------------------------------------------------Manage users Modal validation  STARTS------------------------------------------------------------ 

export const Manage_user_modal_validation = Yup.object({
 
  firstname: Yup.string()
    .required('firstname is required'),
    lastname: Yup.string()
    .required('lastname is required'),
    phonenumber: Yup.string()
    .required('Email is required'),
    email: Yup.string()
    .matches(customEmailFormat,'Invalid email address')
    .required('Email is required'),
    role: Yup.string().when('type', {
      is: 'add',
      then: () => Yup.string().required(),
      otherwise: () => Yup.string(),
  }),
});
// ---------------------------------------------------------Manage users Modal validation  ENDS------------------------------------------------------------ 


export const Manage_post_modal_validation = Yup.object({
  title: Yup.string().required('Title is required'),
  content: Yup.string().required('Content is required'),
  location: Yup.string().required('Location is required'),
  image: Yup.mixed().required('Image is required'),
});


export const Propertyvalidation = Yup.object({
  title: Yup.string().required('Title is required'),
  bathrooms: Yup.number().required('Bathrooms is required'),
  bedrooms: Yup.number().required('Bedrooms is required'),
  built: Yup.number().required('Built is required'),
  price: Yup.number().required('Price is required'),
  cooling: Yup.string().required('Cooling is required'),
  description: Yup.string().required('Description is required'),
  houseType: Yup.string().required('House Type is required'),
  lot: Yup.string().required('Lot is required'),
  sqft: Yup.number().required('Square Feet is required'),
  stories: Yup.number().required('Stories is required'),
  type: Yup.string().required('Type is required'),
  'location.number': Yup.string().required('Number is required'),
  'location.street': Yup.string().required('Street is required'),
  'location.state': Yup.string().required('State is required'),
  'location.city': Yup.string().required('City is required'),
  'location.country': Yup.string().required('Country is required'),
  'location.zip_or_postcode': Yup.string().required('Zip or Postcode is required'),
});