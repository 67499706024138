// Modal.js

import React from 'react';
import closeicon from '../../../assets/images/Cross.svg'
const Modal = ({ isOpen, onClose, title, content ,type}) => {
  
  console.log(content);
  return (
    <>
    
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div className="fixed inset-0 bg-gray-800 opacity-50" onClick={onClose}></div>
          <div className="relative bg-white p-6 mobile:p-4 w-11/12 sm:w-3/4 xl:w-2/5  rounded-lg  shadow-lg">
            <div>{content}</div>
            <button
              onClick={onClose}
              className="absolute top-4 right-2   text-gray-600 hover:text-gray-900"
            >
              <img src={closeicon} />
              
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
