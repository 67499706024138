import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import { API_BASE_URL } from './services/apiservice';
import axios from './services/axiosinterceptor';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { FilterProvider } from './context/filterContext';


const root = ReactDOM.createRoot(document.getElementById('root'));


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 10000,
      
    },
  },
});

root.render(

  // <React.StrictMode>
   <QueryClientProvider client={queryClient}>
   <FilterProvider>

   <App />
   </FilterProvider>
   <ReactQueryDevtools initialIsOpen={false} />

    </QueryClientProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
