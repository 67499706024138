import React from 'react'

const Loader = () => {
  return (
    <>
      <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-opacity-75 z-50">
        <div class="animate-spin rounded-full border-t-4 border-b-4 border-blue-500 h-16 w-16"></div>
      </div>
    </>
  )
}

export default Loader