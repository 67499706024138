import React, { useEffect, useRef, useState } from 'react';
import add_pic from '../../../../assets/images/add-pic.svg';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import apiService from '../../../../services/apiservice';
import { toast, ToastContainer } from 'react-toastify';
import closeIcon from '../../../../assets/images/Cross.svg';
import plusCloseIcon from '../../../../assets/images/Plus.svg';
import cameraicon from '../../../../assets/images/camera.svg';
import { MultiSelect } from 'primereact/multiselect';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { saveAs } from 'file-saver';

import 'react-toastify/dist/ReactToastify.css';
import { useQueryClient } from '@tanstack/react-query';
import { ImageCropperModal } from '../cropperModal/ImageCropperModal';

export const ManageNFTOwnersModal = ({ isOpen, onClose, type, userData }) => {
  const queryKeyForUsers = ['manageuser']; // Adjust the query key structure
  const queryClient = useQueryClient();

  const [imagePreview, setImagePreview] = useState(userData?.imageProfile || null);
  const [phone, setPhone] = useState(userData?.phoneNumber || '');
  const [firstname, setFirstName] = useState(userData?.firstName || '');
  const [lastname, setLastName] = useState(userData?.lastName || '');
  const [email, setEmail] = useState(userData?.email || '');
  const [isSubmitting, setIsSubmitting] = useState(false); // Add state for button disabled state
  const [selectedRole, setSelectedRole] = useState([]); // New state for selected role

  const [phoneError, setPhoneError] = useState('');
  const [firstnameError, setFirstNameError] = useState('');
  const [lastnameError, setLastNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [imageError, setImageError] = useState('');
  const [roleError, setRoleError] = useState('');
  const [image, setImage] = useState(null); // Add state for image file

  const [ownerType, setOwnerType] = useState('single'); // New state for owner type
  const [file, setFile] = useState(null); // New state for file upload
  const [selectedFile, setSelectedFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null);

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.addEventListener('DOMNodeInserted', event => {
        const { currentTarget: target } = event;
        target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
      });
    }
  };

  useEffect(() => {
    scrollToBottom();
    if (isOpen) {
      scrollToBottom();
    }
  }, [isOpen]);

  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError('Enter a valid email address');
      return false;
    }
    setEmailError('');
    return true;
  };

  const handlePhoneChange = (value) => {
    setPhone(value);
  };

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    setImage(selectedImage);
    if (!selectedImage) {
      setImageError('Image is required');
      return;
    }
    const previewURL = URL.createObjectURL(selectedImage);
    setImagePreview(previewURL);
    setImageError('');
  };

  const handleFileChange = (e) => {
    let file = e.target.files[0];
    setFile(file)
    if (file) {
      console.log('this sis file ', file);
      setSelectedFile(file);
      if (file.type.startsWith('text/')) {
        const reader = new FileReader();
        reader.onloadend = () => {
          console.log('if');
          setFilePreview(reader.result);
        };
        reader.readAsDataURL(file);
      } else {
        console.log('else');
        setFilePreview(null);
      }
    }
  };

  const handleSubmit = async () => {
    console.log('this is in submit', file);
    try {
      setIsSubmitting(true);

      let check;

      if (type === 'add') {
        check = (ownerType === 'single' ? !email : !file);
      }

      if (check) {
        if (ownerType === 'single' && !email) {
          setEmailError('Email is required');
          if (!toast.isActive('custom-toast-id-email')) {
            toast.error('Please enter valid email', { toastId: 'custom-toast-id-email', position: toast.POSITION.TOP_RIGHT });
          }
          setIsSubmitting(false);
          return;
        } 
        if (ownerType === 'multiple' && !file) {
          setRoleError('File upload is required');
          if (!toast.isActive('custom-toast-id-file')) {
            toast.error('Please select a file', { toastId: 'custom-toast-id-file', position: toast.POSITION.TOP_RIGHT });
          }
          setIsSubmitting(false);
          return;
        }
      }
      
      

      if (ownerType === 'single' && !validateEmail()) {
        setIsSubmitting(false);
        return;
      }

      const rolesArray = [selectedRole];
      if (type === 'edit') {
        const updatedUserData = {
          id: userData.id,
          firstName: firstname,
          lastName: lastname,
          phoneNumber: phone,
          image: croppedImageFile
        };

        await apiService.editUser(updatedUserData);
        console.log('User updated successfully');
        onClose();
        toast.success('User edited successfully', { position: toast.POSITION.TOP_RIGHT });
        queryClient.invalidateQueries(queryKeyForUsers);
      } else if (type === 'add') {
        if (ownerType === 'single') {
          await apiService.addNftOwners({
            email,
          });
          toast.success('Email added successfully', { position: toast.POSITION.TOP_RIGHT });
        } else if (ownerType === 'multiple') {
          console.log('this is file choosen', file);
          const formData = new FormData();
          formData.append('file', file);
          await apiService.addMultipleUsers(formData);
          toast.success('File imported successfully', { position: toast.POSITION.TOP_RIGHT });

        }
        onClose();
        setIsSubmitting(false);
        queryClient.invalidateQueries(queryKeyForUsers);
      }

      onClose();
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        const data = error.response.data;

        if (status === 400) {
          // toast.error(` ${data.message}`, { position: toast.POSITION.TOP_RIGHT });
          if (!toast.isActive('custom-toast-id-file')) {
            toast.error(` ${data.message}`, { toastId: 'custom-toast-id-file', position: toast.POSITION.TOP_RIGHT });
          }
        } else if (status === 500) {
          // toast.error(`${data.message}`, { position: toast.POSITION.TOP_RIGHT });
          if (!toast.isActive('custom-toast-id-file')) {
            toast.error(` ${data.message}`, { toastId: 'custom-toast-id-file', position: toast.POSITION.TOP_RIGHT });
          }
        } else {
          // toast.error(`${data.message || 'Error updating user'}`, { position: toast.POSITION.TOP_RIGHT });
          if (!toast.isActive('custom-toast-id-file')) {
            toast.error(` ${data.message}`, { toastId: 'custom-toast-id-file', position: toast.POSITION.TOP_RIGHT });
          }
        }
      } else if (error.request) {
        // toast.error('No response received from the server', { position: toast.POSITION.TOP_RIGHT });
        if (!toast.isActive('custom-toast-id-file')) {
          toast.error('No response received from the server', { toastId: 'custom-toast-id-file', position: toast.POSITION.TOP_RIGHT });
        }
      } else {
        // toast.error(`Error: ${error.message}`, { position: toast.POSITION.TOP_RIGHT });
        if (!toast.isActive('custom-toast-id-file')) {
          toast.error(`Error: ${error.message}`, { toastId: 'custom-toast-id-file', position: toast.POSITION.TOP_RIGHT });
        }
      }

      console.error('Error updating user:', error);
      setIsSubmitting(false);
    }
  };

  const handleRemoveFile = () => {
    setFile(null)
    setSelectedFile(null);
    setFilePreview(null);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();

    const droppedFiles = e.dataTransfer.files;

    if (droppedFiles.length > 0) {
      const selectedImage = droppedFiles[0];
      setImage(selectedImage);

      const previewURL = URL.createObjectURL(selectedImage);
      setImagePreview(previewURL);
    }
  };

  const blobToDataURL = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const [croppedImageFile, setCroppedImageFile] = useState(null);
  const [croppedImagePreview, setCroppedImagePreview] = useState(null);

  const handleCroppedImage = (croppedImageBlob) => {
    setCroppedImagePreview(croppedImageBlob);
    fetch(croppedImageBlob)
      .then((response) => response.blob())
      .then((blob) => {
        const croppedImageFile = new File([blob], 'cropped-image.png', { type: 'image/png' });
        console.log('Cropped Image File:', croppedImageFile);
        setCroppedImageFile(croppedImageFile);
      })
      .catch((error) => {
        console.error('Error fetching or converting Blob URL:', error);
      });
  };

  // const handleSubmit = () => {
  //   if (ownerType === 'multiple' && !selectedFile) {
  //     toast.error('Please select a file.');
  //     return;
  //   }

  //   // Continue with form submission logic
  //   console.log('Form submitted');
  // };


  const downloadSampleCSV = () => {
    const csvData = `email
    farzad@obirealestate.com	          
    amir@obirealestate.com	
    joseph@atlascorp.io	`;

    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'sample.csv');
  };

  return (
    <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog max-w-3x1">
        <div className="modal-content rounded-10 border-0">
          <div className="modal-body p-30">
            <div className="flex justify-between items-center mb-4">
              <button className="cursor-pointer"></button>
              <h3 className="text-center text-2xl font-semibold">{type === 'edit' ? 'Edit NFT Owners' : 'Add NFT Owners'}</h3>
              <div></div>
            </div>
            <div className='overflow-y-auto overflow-x-hidden pr-3 h-full '>
              <div className="flex items-center mb-4 ml-28 mobile:ml-8">
                <label className="mr-4 relative">
                  <input
                    type="radio"
                    value="single"
                    checked={ownerType === 'single'}
                    onChange={(e) => setOwnerType(e.target.value)}
                    className="hidden"
                  />
                  <div className={`cursor-pointer ${ownerType === 'single' ? 'border-b-2 border-black' : ''}`}>
                    Add Single Owner
                  </div>
                </label>
                <label className="relative">
                  <input
                    type="radio"
                    value="multiple"
                    checked={ownerType === 'multiple'}
                    onChange={(e) => setOwnerType(e.target.value)}
                    className="hidden"
                  />
                  <div className={`cursor-pointer ${ownerType === 'multiple' ? 'border-b-2 border-black' : ''}`}>
                    Upload CSV file
                  </div>
                </label>
              </div>

              {ownerType === 'single' && (
                <div className="relative w-full sm:w-full px-4 mb-4">
                <input
                  type="email"
                  name="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailError('');
                  }}
                  className={`block w-full h-16 px-2.5 pb-2.5 pt-4 text-sm text-gray-900 bg-transparent rounded-lg border-2 border-solid border-gray-700 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer ${emailError ? 'border-red-500' : ''}`}
                  placeholder="Email"
                />
                {emailError && <p className="text-red-500 text-sm mt-2">Please enter valid email</p>}
              </div>
            )}

              {ownerType === 'multiple' && (
                <div className="relative w-full sm:w-full px-4 mb-4">
                  <label className="block text-sm font-medium text-gray-700">Choose File</label>
                  <div className="mt-2 flex items-center">
                    <input
                      type="file"
                      onChange={handleFileChange}
                      className="hidden"
                      id="file-upload"
                      accept=".csv"
                    />
                    <label htmlFor="file-upload" className="cursor-pointer bg-gray-500 hover:bg-gray-400 text-sm text-white py-2 px-4 rounded-lg">
                      Select File
                    </label>
                    {selectedFile && (
                      <div className="ml-4 flex items-center">
                        <span className="text-sm text-gray-900">{selectedFile.name}</span>
                        <button className="ml-2 text-red-500" onClick={handleRemoveFile}>
                          <img src={plusCloseIcon} alt="Remove" className="w-4 h-4" />
                        </button>
                      </div>
                    )}
                  </div>
                  {/* {filePreview && (
                    <div className="mt-2">
                      {selectedFile.type.startsWith('image/') ? (
                        <img src={filePreview} alt="File Preview" className="max-w-full h-auto rounded-lg" />
                      ) : (
                        <p className="text-sm text-gray-900">{selectedFile.name}</p>
                        // Add specific handling for other file types here
                      )}
                    </div>
                  )} */}
                  <button
                    className="bg-black text-white text-sm py-2 h-10 w-[200px] mobile:w-[180px] mobile rounded-md mt-4"
                    onClick={downloadSampleCSV}
                  >
                    Download Sample CSV
                  </button>
                </div>
              )}

              <button
                className="w-full md:w-524 h-16 px-4 md:px-12 justify-center mt-4 bg-black text-white rounded-lg"
                onClick={handleSubmit}
                disabled={false} // Enable the button for testing
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

};
