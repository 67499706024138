import React from 'react'
import apiService from '../../../../services/apiservice'
import { toast,  } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useQueryClient } from '@tanstack/react-query';

export const DeleteModal = ({isOpen, onClose, title, Data ,type}) => {
    const queryClient = useQueryClient();
const closeDeleteConfirmation = () => {

    onClose()
}



  
const handleDelete = async () => {
  try {
    let result;

    if (type === 'support-request') {
      result = await apiService.deleteSupportRequest(Data);
    } else if (type === 'post-report') {
      
      result = await apiService.delete_post_req(Data);
    } 
    else if (type === 'FlagPost') {
      const flagpost = {
        type: 'unflag'
      };      result = await apiService.delete_post_req(Data,flagpost);
    } 
    
    else if (type === 'Manage-properties') {
      
      result = await apiService.delete_Manage_properties(Data);
    }  else if (type === 'Manage-users') {
      
      result = await apiService.deleteUser(Data);
    }   else if (type === 'Manage-posts') {
      
      result = await apiService.deletePosts(Data);
    }   else if (type === 'Nft-owners') {
      
      result = await apiService.deleteNftUser(Data);
    }

    if (result && result.statusCode === 200) {
      queryClient.invalidateQueries('SupportRequests');
      closeDeleteConfirmation();
      toast.success(result.message);
    } else {
      console.error('Error deleting user:', result);
      toast.error(result.message || 'Error deleting user');
    }
  } catch (error) {
    console.error('Unexpected error deleting user:', error);
    toast.error('Unexpected error deleting user');
  }
};


return (
<div className="text-gray-700 flex flex-col items-center">
      <p className="mb-8 text-xl  font-bold">
        Are you sure you want to {type === 'FlagPost' ? 'flag' : 'delete'} this ?
        
      </p>
      <div className="flex flex-col sm:flex-row sm:space-x-6">
        <button
          className="bg-red-500 text-white w-full sm:w-auto px-16 py-4 rounded-md mb-4 sm:mb-0 hover:bg-red-600 focus:outline-none focus:ring focus:border-blue-300"
          onClick={handleDelete}

        >
          Yes
        </button>
        <button
          className="bg-blue-500 text-white w-full sm:w-auto px-16 py-4 rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300"
          onClick={closeDeleteConfirmation}
        >
          No
        </button>
      </div>
    </div>  )
}
