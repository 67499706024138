import React, { useContext, useEffect, useRef, useState } from 'react'
import { Header } from '../../common/header/header'
import Sidebar from '../../common/sidebar/sidebar'
import editicon from '../../../assets/images/edit_icon.svg';
import Deleteicon from '../../../assets/images/delete_icon.svg';
import post_pic from '../../../assets/images/post-img.png';
import apiService from '../../../services/apiservice';
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import Modal from '../../common/modal/modal';
import { ManagePostsModal } from '../../common/modal/managepostsModal/mangepostsModal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import prev_button from '../../../assets/images/prevbutton.svg';
import next_button from '../../../assets/images/nextbutton.svg';
import { Link, useNavigate } from 'react-router-dom';
import view_icon from '../../../assets/images/view (2).svg'
import Loader from '../../common/loader/loader';
import useAuthentication from '../../../utils/authHook';
import { DeleteModal } from '../../common/modal/deleteModal/deleteModal';
import FilterContext from '../../../context/filterContext';





const ManagePosts = () => {
  useAuthentication()
  const [modalAddPostOpen, setmodalAddPostOpen] = useState(false);
  const [modalEditPostOpen, setmodalEditPostOpen] = useState(false);
  const [selectedUserForEdit, setSelectedUserForEdit] = useState(null);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [selectedUserForDelete, setSelectedUserForDelete] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(10);
  const [loading, setLoading] = useState(true); // New state variable for loading indicator
  const [filterValue, setFilterValue] = useState('');
  // const { filterValue, setFilterValue } = useContext(FilterContext);

  const [noData, setnoData] = useState(false)
  const queryClient = useQueryClient();
  const Navigate=useNavigate()


  // const openEditPost = () => setmodalEditPostOpen(true);
  const closeEditPost = () => setmodalEditPostOpen(false)


  const openAddPost = () => setmodalAddPostOpen(true);
  const closeAddPost = () => setmodalAddPostOpen(false)
  const openEditPost = (user) => {
    // Set other state or perform other actions related to editing
    setSelectedUserForEdit(user);

    setmodalEditPostOpen(true);
  };



  const openDeleteConfirmation = (user) => {
    setSelectedUserForDelete(user.id);
    setDeleteConfirmationOpen(true);
  };
  
  const closeDeleteConfirmation = () => {
    setSelectedUserForDelete(null);
    setDeleteConfirmationOpen(false);
  };
  
  const handleDeleteUser = async () => {
    try {
      
      const result = await apiService.deletePosts(selectedUserForDelete);
      if (result.statusCode === 200) {
        queryClient.invalidateQueries('manageposts');
        closeDeleteConfirmation();
        toast.success(result.message);
      } else {
        console.error('Error deleting user:', result);
          toast.error(result.message || 'Error deleting user');
      }
    } catch (error) {
      console.error('Unexpected error deleting user:', error);
      
      toast.error('Unexpected error deleting user');
    }
  };


  const fetch_manage_posts = async () => {
    try {
      if(filterValue ===''){

        setLoading(true); 
      }
      const users = await apiService.getposts(skip,take,filterValue);  
      setTotalPages(Math.ceil(users.totalCount / take));
      setnoData(false)        

      if (users.statusCode === 400) {
        // Show toastr for no data found
        toast.warning(users.message || 'No data found');
      }
      return users
    } catch (error) {
      if (error.response && error.response.status === 400 && error.response.data.message === "No Data Found") {
        // Show toastr warning for no data found
  setnoData(true)        
      }
    }finally {
      setLoading(false); // Set loading to false once data is fetched
    }
  };
 
  // const query = useQuery({ queryKey: ['manageposts'], queryFn: fetch_manage_posts, })
  const query = useQuery({
    queryKey: ['manageposts'],
    queryFn: fetch_manage_posts,
    // enabled: false, // Initial fetch will be disabled
  });

console.log('query',query)
useEffect(() => {
  query.refetch();
}, [skip,filterValue]);

const viewpost=(postId)=>{
  Navigate(`/manage-posts/postview/${postId}`);

}












const Pagination = ({ currentPage, setCurrentPage, totalPages }) => {


  const goToPage = (page) => {
    console.log('page value111,',page, 'here si take val',take)

    setCurrentPage(page);
//     setSkip((page*10)-10)
//     console.log('skip value from gotopage',skip)
// // fetch_manage_posts()
//     queryClient.invalidateQueries('manageposts');/
console.log((page - 1) * take);
setSkip((page - 1) * take);
console.log('afterupdate,',skip, 'here si take val',take)
query.refetch();
  };

  const handlePrevClick = () => {
    if (currentPage > 1) {
      goToPage(currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage < totalPages) {
      goToPage(currentPage + 1);
    }
  };
  const generatePageNumbers = () => {
    const pageNumbers = [];
    const maxPageButtons = 3; // Adjust this value to change the number of displayed pagess
    let startPage = Math.max(1, currentPage - Math.floor(maxPageButtons / 2));
    let endPage = Math.min(totalPages, startPage + maxPageButtons - 1);
    if (endPage - startPage + 1 < maxPageButtons) {
      startPage = Math.max(1, endPage - maxPageButtons + 1);
    }
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  };
  return (
    <div className="flex items-center justify-center pt-15 pb-19 mt-5 mb-3 pagination ">
      <ul className="flex list-none">
        <li
          className={`w-12 h-12 flex items-center justify-center cursor-pointer ${
            currentPage === 1 ? 'opacity-30 cursor-not-allowed' : ''
          }`}
          onClick={handlePrevClick}
        >
          <img src={prev_button} alt="Previous Page" />
        </li>
        {generatePageNumbers().map((page) => (
          <li
            key={page}
            className={`ml-2 w-12 h-12 border-2 border-solid border-gray-300 rounded-full text-gray-500 text-lg flex items-center justify-center cursor-pointer ${
              page === currentPage ? 'bg-black shadow-md border-none text-white' : ''
            }`}
            onClick={() => goToPage(page)}
          >
            {page}
          </li>
        ))}
        <li
         className={`ml-2 w-12 h-12 flex items-center justify-center cursor-pointer ${
            currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          onClick={handleNextClick}
        >
          <img src={next_button} alt="Next Page" />
        </li>
      </ul>
    </div>
  );
};



const [isSidebarOpen, setIsSidebarOpen] = useState(false);
const sidebarRef = useRef(null);

const toggleSidebar = () => {
  setIsSidebarOpen(!isSidebarOpen);
};



useEffect(() => {
  const handleBodyClick = (event) => {
    // Check if the click target is outside of the sidebar
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      // Close the sidebar
      setIsSidebarOpen(false);
    }
  };

  // Add event listener to listen for clicks on the document body
  document.body.addEventListener('click', handleBodyClick);

  // Cleanup function to remove event listener when component unmounts
  return () => {
    document.body.removeEventListener('click', handleBodyClick);
  };
}, []);




const handleDashboardClick = () => {
  if(isSidebarOpen){
    setIsSidebarOpen(!isSidebarOpen);
  }
};
const handleFilterChange = (event) => {
  setFilterValue(event.target.value);
};


  return (
    
    <div className="flex flex-col ">
      <Header toggleSidebar={toggleSidebar} />
      <div className=" flex flex-grow">

      <Sidebar isSidebarOpen={isSidebarOpen} ref={sidebarRef} />


      <div className="flex flex-col flex-grow overflow-auto">
      {/* <Sidebar />
      <Header /> */}

      <Modal
    isOpen={modalAddPostOpen}
    onClose={closeAddPost}
    title="Edit User"
    content={<ManagePostsModal  type={'add'}  onClose={closeAddPost} />} // Adjust content as needed
  />

<Modal
    isOpen={modalEditPostOpen}
    onClose={closeEditPost}
    title="Edit User"
    content={<ManagePostsModal  type={'edit'} userData={selectedUserForEdit} onClose={closeEditPost} />} // Adjust content as needed
  />
  
  <Modal
  isOpen={deleteConfirmationOpen}
  onClose={closeDeleteConfirmation}
  title="Delete User"
  content={
  
    <DeleteModal  type={'Manage-posts'} Data={selectedUserForDelete} onClose={closeDeleteConfirmation} />
  }
/>
{loading ? (
      <Loader />
    ) : (

      <div className="HomeMainDiv   "   onClick={handleDashboardClick} >
        {/* <div className="RightSideDiv pl-80 pr-5"> */}
        <div className=' pt-6 pl-5 lg:pl-72 pr-5'>

          <div className="right-side-inner ">
            <div className="user-top mb-6 flex items-center ">
              <h3 className="text-xl font-semibold inline-block">Manage Posts</h3>
              <div className="ml-auto flex items-center">

              {/* <input
    type="text"
    className="border border-gray-300 rounded-lg p-2 mr-4"
    placeholder="Search Post"
    value={filterValue} 
    onChange={(e) => setFilterValue(e.target.value)}
  /> */}

  <div className="relative mr-4">
  <input
    type="text"
    id="search-post"
    className="block w-full h-10 px-2.5 pb-2.5 pt-4 text-sm text-gray-900 bg-transparent rounded-lg border-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
    placeholder=" "
    value={filterValue}
    onChange={(e) => setFilterValue(e.target.value)}
  />
  <label
    htmlFor="search-post"
    className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-customBg px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-2"
  >
    Search Post
  </label>
</div>


              <button className="bg-black text-white text-sm py-2 h-10 px-4 float-right rounded-md" onClick={openAddPost}>
                Add Post
              </button>
            </div>
            </div>
            <div className="table-main bg-white rounded-lg overflow-y-auto    mobile:max-h-[calc(100vh-330px)] max-h-[calc(100vh-275px)]   "  >
              <table className="table w-full ">
                <thead className="text-left">
                  <tr className=''>
                    <th scope="col" className='p-4 font-medium' >Image</th>
                    <th scope="col" className='p-4 font-medium' >Title</th>
                    <th scope="col" className='p-4 font-medium' >Description</th>
                    <th scope="col" className='p-4 font-medium' >Location</th>
                    {/* <th scope="col" className='p-4 font-medium' >Contact</th>
                    <th scope="col" className='p-4 font-medium' >Address</th> */}
                    <th scope="col" className='p-4 font-medium' >Action</th>
                  </tr>
                </thead>
                <tbody>

                {noData ? (
          <tr>
            <td colSpan="5" className="text-center py-4 text-gray-500">No Data Found</td>
          </tr>
        ) : (
                  query?.data?.post?.map((user,index) => (
                      <tr key={user.id} className={` ${index % 2 === 0 ? 'bg-custom-gray' : ''}`}>
                      <td className="p-4">
                      
                      <span className="user-img inline-block rounded-md  xl:w-28 xl:h-16   overflow-hidden sm:w-16 sm:h-10 mobile-image">
                      <img src={user.image} alt="" className="w-full h-full object-cover  "  />
                      </span>


                      </td>
                      <td className="p-4 text-sm">{user.title || '--'}</td>
                      <td className="p-4 text-sm">{user.content || '--'}</td>
                      <td className="p-4 text-sm">{user.location || '--'}</td>
                      <td className="p-4 flex items-center mt-4" > 
                      {/* <td className="p-4  " >  */}

                      <button className="w-8 h-8 bg-red-500 text-white rounded-full mr-2" onClick={() =>viewpost(user.id)}>
                          <img src={view_icon} alt="Delete Icon" />
                        </button>
                        <button className="w-8 h-8 bg-black text-white rounded-full" onClick={() => openEditPost(user)}>
                          <img src={editicon} alt="Edit Icon"  />
                        </button>
                        <button className="w-8 h-8 bg-red-500 text-white rounded-full ml-2" onClick={() =>openDeleteConfirmation(user)}>
                          <img src={Deleteicon} alt="Delete Icon" />
                        </button>
                      </td>
                    </tr>
                    ))
        )}
                </tbody>
              </table>
            </div>
            {/* <Pagination  /> */}
            <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages} />

          </div>
        </div>
      </div>
    )}
    </div>
    </div>
    </div>
  )
}

export default ManagePosts