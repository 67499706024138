


import React, { useState, useRef, useEffect } from 'react';
import closeicon from '../../../../assets/images/Cross.svg';
import { ReactCrop, convertToPixelCrop ,makeAspectCrop , centerCrop,
} from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { canvasPreview } from '../../../../utils/canvasPreview';
import { useDebounceEffect } from '../../../../utils/useDebbounceEffect';

export const ImageCropperModal = ({ onClose, imageUrl ,onCropComplete }) => {
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState(16 / 9);

  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const hiddenAnchorRef = useRef(null);
  const blobUrlRef = useRef('');

  console.log('image url that we getiitng passed ',imageUrl)




// useEffect(() => {
//     if (imageUrl) {
//       const image = new Image();
//       image.src = imageUrl;
//       image.onload = () => {
//         const imageAspect = image.width / image.height;
//         console.log('this is aspect ratio',imageAspect);
//         setAspect(imageAspect);
//         const initialCrop = centerAspectCrop(image.width, image.height, imageAspect);
//         setCrop(initialCrop);
//         setCompletedCrop(convertToPixelCrop(initialCrop, image.width, image.height));
//       };
//     }
//   }, [imageUrl]);

useEffect(() => {
  if (imageUrl) {
      const image = new Image();
      image.src = imageUrl;
      image.onload = () => {
          const width = image.width;
          const height = image.height;

          // Determine the aspect ratio based on image dimensions
          let calculatedAspect;
          if (width === height) {
              calculatedAspect = 1; // 1:1 aspect ratio
          } else if (width > height) {
              calculatedAspect = 4 / 3; // 3:4 aspect ratio
          } else {
              calculatedAspect = 3 / 4; // 4:3 aspect ratio
          }

          // Set the calculated aspect ratio
          setAspect(calculatedAspect);

          // Calculate the initial crop based on the image dimensions and aspect ratio
          const initialCrop = centerAspectCrop(width, height, calculatedAspect);
          setCrop(initialCrop);
          setCompletedCrop(convertToPixelCrop(initialCrop, width, height));
      };
  }
}, [imageUrl]);


  const onImageLoad = (e) => {
    
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  };

  const onDownloadCropClick = async () => {
    const image = imgRef.current;
    const previewCanvas = previewCanvasRef.current;
    if (!image || !previewCanvas || !completedCrop) {
      throw new Error('Crop canvas does not exist');
    }

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const offscreen = new OffscreenCanvas(
      completedCrop.width * scaleX,
      completedCrop.height * scaleY
    );
    const ctx = offscreen.getContext('2d');
    if (!ctx) {
      throw new Error('No 2d context');
    }

    ctx.drawImage(
      previewCanvas,
      0,
      0,
      previewCanvas.width,
      previewCanvas.height,
      0,
      0,
      offscreen.width,
      offscreen.height
    );

    

    const dataUrl = previewCanvas.toDataURL('image/jpeg', 0.1); // Adjust quality as needed
  
    // Convert data URL to Blob
    const blob = await fetch(dataUrl).then((res) => res.blob());
  if (blob.size > 2 * 1024 * 1024) {
    alert('cropped image size exceeds 2mb limit')
    return;
  }


    if (blobUrlRef.current) {
      URL.revokeObjectURL(blobUrlRef.current);
    }
    blobUrlRef.current = URL.createObjectURL(blob);

   
    console.log('here is the bblob val',blobUrlRef.current); 

    if (onCropComplete) {
      onCropComplete(blobUrlRef.current);
    }
  
    // Close the modal
    onClose();

  };


  

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  const handleToggleAspectClick = () => {
    if (aspect) {
      setAspect(undefined);
    } else {
      setAspect(16 / 9);

      if (imgRef.current) {
        const { width, height } = imgRef.current;
        const newCrop = centerAspectCrop(width, height, 16 / 9);
        setCrop(newCrop);
        setCompletedCrop(convertToPixelCrop(newCrop, width, height));
      }
    }
  };

 
  



function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
    console.log('this is center code ');
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 90,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  }
  


  return (
    <div  >
      <div className="fixed inset-0 z-50 flex items-center justify-center ">
        <div className="fixed inset-0 bg-gray-800 opacity-50" onClick={onClose}></div>
        {/* <div className="relative bg-white p-8 w-11/12 sm:w-3/4 xl:w-2/5   rounded-lg shadow-lg"> */} 
        <div className="relative bg-white p-8 w-3/4 max-w-lg xl:w-2/5 rounded-lg shadow-lg">

        {/* <div className="relative bg-white p-8  sm:w-3/4 xl:w-2/5     rounded-lg shadow-lg"> */}

          <div className="    overflow-y-auto mx-auto max-h-[calc(100vh-225px)]  "   >
            {!!imageUrl && (
              <ReactCrop
                crop={crop}
                onChange={(_, percentCrop) => setCrop(percentCrop)}
                onComplete={(c) => setCompletedCrop(c)}
                aspect={aspect}
                className="w-full h-full object-contain"
                style={{ maxHeight: '100%', maxWidth: '100%', transform: `scale(${scale}) rotate(${rotate}deg)` }}

              >
                <img
                  ref={imgRef}
                  alt="Crop me"
                  src={imageUrl}
                  className="w-full h-full object-contain "
                  // style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                  style={{ maxHeight: '100%', maxWidth: '100%', transform: `scale(${scale}) rotate(${rotate}deg)` }}

                  onLoad={onImageLoad}
                />
              </ReactCrop>
            )}
          </div>
          <button
            onClick={onClose}
            className="absolute top-2 right-1 text-gray-600 hover:text-gray-900"
          >
            <img src={closeicon} />
          </button>
          {!!completedCrop && (
            <>
              <div className=' hidden  overflow-hidden mx-auto'>
                <canvas
                  ref={previewCanvasRef}
                  style={{
                    border: '1px solid black',
                    objectFit: 'cover',
                    width: completedCrop.width,
                    height: completedCrop.height,
                  }}
                />
              </div>
              <div>
                <button onClick={onDownloadCropClick}                 className="flex w-4/5 md:w-524 h-16 md:h-61  md:px-12 justify-center items-center bg-black text-white mt-4 mx-auto my-auto rounded-lg"
 > Crop</button>
               
                <a
                  href="#hidden"
                  ref={hiddenAnchorRef}
                  download
                  style={{
                    position: 'absolute',
                    top: '-200vh',
                    visibility: 'hidden',
                  }}
                >
                  Hidden download
                </a>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
