

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useAuthentication = () => {
 

};

export default useAuthentication;
