import React from 'react'
import dummyimage from  '../../../../assets/images/placeholder-image-person-jpg.jpg'

export const UserReportviewModal = ({ title, type, Data, onClose }) => {

console.log(Data,'here is the data for the modal ')

  return (
    <div >

<div className="user-report-view-modal flex flex-row items-center mobile:flex-col mobile:-ml-28 ">
      <div className="user-top mb-15 w-1/2">
        <div className="user-info">
        <h4 className='text-sm mb-2'>Reported by</h4>
          <span className="user-img inline-block w-10 h-10 bg-ddd border-radius-50 rounded-full overflow-hidden mr-2">
            <img src={Data.reporter?.imageProfile || dummyimage} alt="" className="w-full h-full object-cover" />
          </span>
          <div className="user-details inline-block vertical-align-middle pl-2">
            <h3 className="text-15px text-22242C ">{Data.reporter?.userName || ' --'}</h3>
            <h4 className="text-xs text-gray-500 mobile:line-clamp-1 md:line-clamp-1 xl:line-clamp-none ">{Data.reporter?.email || '--'}</h4>
          </div>
        </div>
      </div>

      <div className="user-top mb-15 w-1/2">
        <div className="user-info">
        <h4 className='text-sm mb-2'>Reported to</h4>

          <span className="user-img inline-block w-10 h-10 bg-ddd border-radius-50 rounded-full overflow-hidden mr-2">
            <img src={Data.reported?.imageProfile || dummyimage} alt="" className="w-full h-full object-cover" />
          </span>
          <div className="user-details inline-block vertical-align-middle pl-2">
            <h3 className="text-15px text-22242C ">{Data.reported?.userName || '--'}</h3>
            <h4 className="text-xs text-gray-500 mobile:line-clamp-1 md:line-clamp-1 xl:line-clamp-none ">{Data.reported?.email || '--'}</h4>
          </div>
        </div>
      </div>
    </div>
    <div className="user-details mt-4">
  <p>
    <span className="font-bold">Reason:</span> {Data.reason || '--'}
  </p>
  <p>
    <span className="font-bold">Status:</span> {Data.status || '--'}
  </p>
  <p>
    <span className="font-bold">Description:</span> {Data.description || 'No description provided'}
  </p>
  {/* ... */}
</div>
    </div>
  )
}
