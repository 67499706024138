import logo from './logo.svg';
import './App.css';
import Login from './components/auth/login';
import {Header} from './components/common/header/header';
import Sidebar from './components/common/sidebar/sidebar';
import ManageUsers from './components/layout/manage-user/ManageUsers';
import AppRoutes from './routing/router';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthProvider } from './context/authContext';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FilterProvider } from './context/filterContext';


function App() {
  
  
  return (
    <div className="App">

<link rel="preconnect" href="https://fonts.googleapis.com"></link>
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin></link>
<link href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap" rel="stylesheet">

</link>
    
     
<AuthProvider>

      <AppRoutes></AppRoutes>
</AuthProvider>
      <ToastContainer
position="top-right"
autoClose={2000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>  
  </div>
  );
}

export default App;
