import React, { useEffect, useState } from 'react'
import Sidebar from '../../../common/sidebar/sidebar'
import { Header } from '../../../common/header/header'
import profile_img from '../../../../assets/images/img6.png'
import {   useParams } from 'react-router-dom';
import apiService from '../../../../services/apiservice';
import {  useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import back_button from '../../../../assets/images/Caret left.svg'


const SupportRequestView = () => {
    const { requestId } = useParams();
    const [supportData, setsupportData] = useState(null);
    const Navigate = useNavigate()

    useEffect(() => {
    const supportRequestDetials = async () => {
        try {
          const Data = await apiService.get_support_request_view(requestId);
          setsupportData(Data); 
        } catch (error) {
        }
      };
      supportRequestDetials();
      }, [requestId]);

      const updatestatus = async (supportId) => {
        try {
          await apiService.updatestatus(supportId,'Accepted');
          toast.success('Support request is accepted')
          Navigate(`/support-requests`);  
        } catch (error) {
            toast.error('Your request not accepted')

        }
      };

      const [isSidebarOpen, setIsSidebarOpen] = useState(false);

      const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
      };

    // const cancelfunction=()=>{
    //   toast.error('Support request is rejected')

    //     Navigate(`/support-requests`);  }

 const routeback=() => {
  Navigate(`/support-requests`);  }

 
  const cancelfunction = async () => {
    try {
      await apiService.updatestatus(supportData?.id, 'Rejected');
      toast.success('Support request is rejected');
      Navigate(`/support-requests`);
    } catch (error) {
      toast.error('Failed to reject support request');
    }
  };
  



  return (
    <div className="flex flex-col ">
    <Header toggleSidebar={toggleSidebar} />
    <div className=" flex flex-grow overflow-auto">

    <Sidebar isSidebarOpen={isSidebarOpen} />


<div className="HomeMainDiv w-full">
<div className="RightSideDiv sm:pl-4 lg:pl-80 pr-5 w-full" >
        

<div className="right-side-inner posts">
  <div className="user-top flex items-center">
  <img src={back_button} alt='back button' onClick={routeback} className='mt-4 mr-6 cursor-pointer' />

  <h3 className="text-2xl font-semibold inline-block mt-4 mobile:ml-4">Support Requests</h3>
  </div>
  <div className="table-main request2 mt-10 ">
    <div className="request-top">
      <div className="request-left inline-block">
        <span className="w-12 h-12 bg-gray-300 inline-block rounded-full overflow-hidden mobile:ml-2">
          <img src={supportData?.profileImg || profile_img} alt="" className="w-full h-full object-cover" />
        </span>
        <div className="request-text inline-block pl-4">
          <h3 className="text-xl font-semibold text-gray-700 mb-1">{ supportData?.name||'Dori Doreau'}</h3>
          <h4 className="text-gray-600 text-sm">{ supportData?.email ||'samantha@mail.com'}</h4>
        </div>
      </div>
      <button className="pending float-right bg-gray-200 text-gray-600 text-sm px-8 py-2 mobile:mt-5 rounded-full font-semibold border-0 my-2 mr-8 ">
        {supportData?.status || 'status'}
      </button>
    </div>
   
    <div className="request-list mb-4 mt-6">
      <h4 className="text-base font-normal inline-block w-26 mobile:ml-4   align-top">Date :</h4>
      <p className="text-base font-normal inline-block w-26 mobile:ml-4 ml-2 ">
      {/* {supportData?.createdAt.split('T')[0]} */}
      {supportData?.createdAt.split('T')[0].split('-').reverse().join('-')}

      </p>
    </div>
    <div className="request-list mb-4">
      <h4 className="text-base font-normal inline-block w-26 align-top mobile:ml-4 ">Description :</h4>
      <p className="text-base font-normal inline-block w-26 ml-2 mobile:ml-4 overflow-wrap break-word  ">
       {supportData?.description || 'Description'}
      </p>
    </div>
    <div className="request-btns text-center mt-9">
    {supportData?.status === 'Pending' && (
<>
      <button className="bg-blue-500 text-white w-48 mobile:w-36 h-12 rounded-md font-semibold mr-2"   onClick={() => updatestatus(supportData?.id)} >Accept</button>
      <button className="cancel bg-pink-600 text-white mobile:w-36 w-48 h-12 rounded-md font-semibold" onClick={cancelfunction}>Reject</button>
      
</>
)}
    </div>
  </div>
</div>

</div>
</div>
    </div>
    </div>
    
  )
}

export default SupportRequestView