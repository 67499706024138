
import React, {  useState } from 'react';
import add_pic from '../../../../assets/images/add-pic.svg';
import 'react-phone-input-2/lib/bootstrap.css';
import apiService from '../../../../services/apiservice';
import { toast,  } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { useQueryClient } from '@tanstack/react-query';
import cameraicon from '../../../../assets/images/camera.svg'
import { ImageCropperModal } from '../cropperModal/ImageCropperModal';


export const ManagePostsModal = ({ isOpen, onClose, type, userData }) => {
  const queryKeyForUsers = ['manageposts']; // Adjust the query key structure
  const queryClient = useQueryClient();
console.log('here is the use data  form  manage post ',userData)

  const [title,setTitle] = useState(userData?.title || '');
  const [content,setContent] = useState(userData?.content || '');
  const [location,setLocation] = useState(userData?.location || '');
  const [imagePreview, setImagePreview] = useState(userData?.image || null);

  const [isSubmitting, setIsSubmitting] = useState(false); // Add state for button disabled state
 // Add state variables for validation
 const [titleError, setTitleError] = useState('');
 const [contentError, setContentError] = useState('');
 const [locationError, setLocationError] = useState('');
 const [imageError, setImageError] = useState('');
 const [croppedImagePreview, setCroppedImagePreview] = useState(null);
 const [thumbnail, setThumbnail] = useState(null)

  const [imageHeight, setimageHeight] = useState(null); // Add state for image file
  const [imageWidth, setimageWidth] = useState(null); // Add state for image file
  const [imageModalOpen, setImageModalOpen] = useState(false);

  const [image, setImage] = useState(null); // Add state for image file

  
  const clearAllToasts = () => {
    toast.dismiss();
  };
  const handleSubmit = async () => {
    try {
      // Reset error messages
      setTitleError('');
      setContentError('');
      setLocationError('');
      setImageError('');

      setIsSubmitting(true);

      // Check if any input field is empty
      if (!title || !content || !location ) {
        // Update error messages
        if (!title) setTitleError('Title is required');
        if (!content) setContentError('Content is required');
        if (!location) setLocationError('Location is required');
        if (type === 'add' && !image) {
          setImageError('Image is required');
          setIsSubmitting(false);
          return;
        }
        clearAllToasts()
        toast.error('Please fill in all details', { position: toast.POSITION.TOP_RIGHT });
        setIsSubmitting(false);
        return;
      }
     
      
      if (type === 'edit') {
        const updatedUserData = {
          id: userData.id,
          title: title,
          content: content,
          image:croppedImageFile ,
          thumbnail:thumbnail ,

          location:location,
          height: imageHeight,
          width :imageWidth,
          
          // Add other properties as needed
        };
  
        await apiService.editposts(updatedUserData);
        console.log('Post updated successfully');
        onClose();
  
        toast.success('Post updated successfully', { position: toast.POSITION.TOP_RIGHT });
        queryClient.invalidateQueries(queryKeyForUsers);
  
      } else if (type === 'add') {
        // Call the addUser API
        console.log('here is thumbnail val',thumbnail)
        await apiService.addPosts({
          title: title,
          content: content,
          image:croppedImageFile,
          thumbnail:thumbnail ,

          location:location,
         height: imageHeight,
          width :imageWidth,

        });
        onClose();
        setIsSubmitting(false); // Set disabled state to true
        toast.success('Post added successfully', { position: toast.POSITION.TOP_RIGHT });

        queryClient.invalidateQueries(queryKeyForUsers);
  
      }
  
      // Close the modal or perform other actions
      onClose();
    } catch (error) {
      setIsSubmitting(false); // Set disabled state to true

      if (error.response) {
     
        const status = error.response.status;
        const data = error.response.data;
  
        if (status === 400) {
          toast.error(` ${data.message}`, { position: toast.POSITION.TOP_RIGHT });
        } else if (status === 500) {
          toast.error(`Internal Server Error: ${data.message}`, { position: toast.POSITION.TOP_RIGHT });
        } else {
          toast.error(`Unexpected Error: ${data.message || 'Error updating user'}`, { position: toast.POSITION.TOP_RIGHT });
        }
      } else if (error.request) {
        // The request was made but no response was received
        toast.error('No response received from the server', { position: toast.POSITION.TOP_RIGHT });
      } else {
        // Something happened in setting up the request that triggered an Error
        toast.error(`Error: ${error.message}`, { position: toast.POSITION.TOP_RIGHT });
      }
  
      console.error('Error updating user:', error);
    }
  };

 
  
const handleImageChange = (e) => {
  const selectedImage = e.target.files[0];
  setImageModalOpen(true);

  if (!selectedImage) {
    setImageError('Image is required');
    return;
  }

    // Check if the file size exceeds the 20 MB limit
  //   if (selectedImage.size > 20 * 1024 * 1024) {
  //     toast.error('The selected image exceeds the 20 MB size limit. please select image less than 20 MB',{autoClose:3000});
  //     setImageModalOpen(false);
  //     return;
  // }


  // Create a FileReader to read the image file
  const reader = new FileReader();

  reader.onload = (event) => {
    // Create an Image element to get image dimensions
    const img = new Image();
    img.src = event.target.result;

    img.onload = () => {
      // Calculate image dimensions
      const width = img.width;
      const height = img.height;

      // Set the image state and preview URL
     
      setImage(selectedImage);
      setImagePreview(event.target.result);
      setImageError('');

    };
  };

  // Read the image as a data URL
  reader.readAsDataURL(selectedImage);
};

// ---------------------------------------------------------------------image drag and drip event handler-------------------------------------------
const handleDragOver = (e) => {
  e.preventDefault();
};

const handleDrop = (e) => {
  e.preventDefault();

  const droppedFiles = e.dataTransfer.files;

  if (droppedFiles.length > 0) {
    const selectedImage = droppedFiles[0];
    setImage(selectedImage);
    setImageModalOpen(true);

    const previewURL = URL.createObjectURL(selectedImage);
    setImagePreview(previewURL);
  }
};

// ---------------------------------------------------------------------image drag and drip event handler-------------------------------------------

const [croppedImageFile, setCroppedImageFile] = useState(null);



const handleCroppedImage = (croppedImageBlob) => {
  
  setCroppedImagePreview(croppedImageBlob);
 fetch(croppedImageBlob)
 .then((response) => response.blob())
 .then((blob) => {
   // Create a new File object from the blob
   const croppedImageFile = new File([blob], 'cropped-image.png', { type: 'image/png' });
   console.log('Cropped Image File:', croppedImageFile);

   // Update the state with the cropped image File
   setCroppedImageFile(croppedImageFile);
   generateThumbnail(croppedImageFile);
   calculateImageDimensions(croppedImageFile);

 })
 .catch((error) => {
   console.error('Error fetching or converting Blob URL:', error);
 });


};

const calculateImageDimensions = (imageFile) => {
  const reader = new FileReader();

  reader.onload = function (e) {
    const img = new Image();
    img.src = e.target.result;

    img.onload = function () {
      const height = img.height;
      const width = img.width;
      setimageHeight(height)
      setimageWidth(width)
      console.log('Image Dimensions - Height:', height, 'Width:', width);
      // You can now use the height and width as needed.
    };
  };

  reader.readAsDataURL(imageFile);
};




const generateThumbnail = async (croppedImageBlob) => {
  try {
    // Convert the cropped image Blob to a Data URL
    const croppedImageDataUrl = await blobToDataURL(croppedImageBlob);

    // Create a new Image element for the cropped image
    const img = new Image();
    img.src = croppedImageDataUrl;

    img.onload = async () => {
      const thumbnailCanvas = document.createElement('canvas');
      const thumbnailCtx = thumbnailCanvas.getContext('2d');
      const targetSizeKB = 200;
      let compressionQuality = 1; // Initial compression quality
      const maxIterations = 10; // Maximum number of iterations to avoid infinite loop

      // Calculate the aspect ratio for resizing
      const aspectRatio = img.width / img.height;
      let thumbnailWidth = Math.sqrt(targetSizeKB * 1024 * aspectRatio);
      let thumbnailHeight = thumbnailWidth / aspectRatio;

      // Set the canvas size
      thumbnailCanvas.width = thumbnailWidth;
      thumbnailCanvas.height = thumbnailHeight;

      let thumbnailBlob;
      let iterations = 0;

      while (true) {
        // Clear the canvas
        thumbnailCtx.clearRect(0, 0, thumbnailWidth, thumbnailHeight);

        // Draw the resized image onto the canvas
        thumbnailCtx.drawImage(img, 0, 0, thumbnailWidth, thumbnailHeight);

        // Convert the canvas content to a blob
        thumbnailBlob = await new Promise((resolve) => {
          thumbnailCanvas.toBlob(resolve, 'image/jpeg', compressionQuality);
        });

        // Check if the resulting thumbnail size is within the target size
        if (thumbnailBlob.size <= targetSizeKB * 1024 || iterations >= maxIterations) {
          break;
        }

        // Adjust compression quality for the next iteration
        compressionQuality -= 0.1;

        // Recalculate dimensions for the next iteration
        thumbnailWidth = Math.sqrt(thumbnailBlob.size * aspectRatio);
        thumbnailHeight = thumbnailWidth / aspectRatio;

        iterations++;
      }

      // Create a File object from the thumbnail blob
      const thumbnailFile = new File([thumbnailBlob], 'thumbnail.png', { type: 'image/png' });
      if (thumbnailBlob.size > targetSizeKB * 1024) {
        alert('The generated thumbnail exceeds 200 KB.');
      } else {
        console.log('Thumbnail File:', thumbnailFile);
        setThumbnail(thumbnailFile);
        // Perform actions with the thumbnail file as needed
        // For example, you can use the thumbnailFile in your API call or state.
      }
    };
  } catch (error) {
    console.error('Error generating thumbnail:', error);
  }
};



const blobToDataURL = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};



  return (
    <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    {imageModalOpen && (
  <ImageCropperModal imageUrl={imagePreview} onClose={() => setImageModalOpen(false)}   onCropComplete={(croppedImageBlob) => handleCroppedImage(croppedImageBlob)} />
)}
      <div className="modal-dialog max-w-3x1">
        <div className="modal-content rounded-10 border-0">
          <div className="modal-body p-30">
          <h3 className="text-center text-2xl font-semibold">{type === 'edit' ? 'Edit Post' : 'Add Post'}</h3>
          <div className= 'mobile:overflow-y-auto  mobile:overflow-x-hidden mobile:pr-3 pr-2  mobile:h-full  relative mobile:max-h-[calc(100vh-285px)]   overflow-y-auto overflow-x-hidden  max-h-[calc(100vh-225px)] '  > 

          {/* <div className= 'mobile:overflow-y-auto  mobile:overflow-x-hidden mobile:pr-2  mobile:h-screen  mobile:relative mobile:max-h-[calc(100vh-285px)] '  >  */}

          <div className="add-user-pic w-200 h-200 m-7 bg-F5F5F5 rounded-full">
  <label
    id="dropcontainer"
    className={`drop-container flex bg-slate-300 w-44 h-44 flex-col justify-center rounded-full text-black cursor-pointer p-0 m-auto items-center ${
              imageError ? 'border-red-500' : ''
            }`}    onDragOver={(e) => handleDragOver(e)}
  onDrop={(e) => handleDrop(e)}
  >




    {croppedImagePreview ? (
  <img src={croppedImagePreview} alt="Cropped Image Preview" className="preview-img" />
) : imagePreview ? (
  <img src={imagePreview} alt="Image Preview" className="preview-img" />
) : (
  <img src={add_pic} alt="Add Photo" />
)}


    <div className={`relative top-${imagePreview ? '8' : '0'} left-24 bg-black   w-10 h-10 rounded-full flex items-center justify-center`}>

        <img src={cameraicon} />
      </div>

    <input type="file" id="images" accept="image/*" required="" className="hidden" onChange={handleImageChange}   onDragOver={(e) => handleDragOver(e)}
  onDrop={(e) => handleDrop(e)} />
    <h3 className="text-17 font-semibold -mt-6 ">Add Photo</h3>
    <h5 className="text-xs font-normal text-909090 mb-3">or drag and drop</h5>
  </label>
  {imageError && <p className="text-red-500 text-sm text-center mx-auto ">{imageError}</p>}

</div>

         
           
         

          <div className="mb-4">
              {/* <input
                  type="text"
                  name="title"
                  value={title}
                  onChange={(e) => {
              setTitle(e.target.value);
              setTitleError('');
            }}
            className={`w-full h-16 px-4 rounded-lg border-2 border-solid border-gray-700 ${
              titleError ? 'border-red-500' : ''
            }`}
                             placeholder="Title"
                /> */}


                <div className="relative">
  <input
    type="text"
    name="title"
    value={title}
    onChange={(e) => {
      setTitle(e.target.value);
      setTitleError('');
    }}
    className={`block w-full h-16 px-2.5 pb-2.5 pt-4 text-sm text-gray-900 bg-transparent rounded-lg border-2 border-solid border-gray-700 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer ${titleError ? 'border-red-500' : ''}`}
    placeholder=" "
  />
  <label
    htmlFor="title"
    className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-2"
  >
    Title
  </label>
</div>



                          {titleError && <p className="text-red-500 text-sm">{titleError}</p>}

              </div>

            <div className='mb-4'>
            {/* <input
                  type="text"
                  name="content"
                  value={content}
                  onChange={(e) => {
              setContent(e.target.value);
              setContentError('');
            }}
            className={`w-full h-16 px-4 rounded-lg border-2 border-solid border-gray-700 ${
              contentError ? 'border-red-500' : ''
            }`}
            placeholder="Content"
          /> */}


          <div className="relative">
  <input
    type="text"
    name="content"
    value={content}
    onChange={(e) => {
      setContent(e.target.value);
      setContentError('');
    }}
    className={`block w-full h-16 px-2.5 pb-2.5 pt-4 text-sm text-gray-900 bg-transparent rounded-lg border-2 border-solid border-gray-700 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer ${contentError ? 'border-red-500' : ''}`}
    placeholder=" "
  />
  <label
    htmlFor="content"
    className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-2 "
  >
    Content
  </label>
</div>


          {contentError && <p className="text-red-500 text-sm">{contentError}</p>}
        </div>
            <div className='mb-4'>
            {/* <input
                  type="text"
                  name="location"
                  value={location}
                  onChange={(e) => {
              setLocation(e.target.value);
              setLocationError('');
            }}
            className={`w-full h-16 px-4 rounded-lg border-2 border-solid border-gray-700 ${
              locationError ? 'border-red-500' : ''
            }`}
            placeholder="Location"
          /> */}

          <div className="relative">
  <input
    type="text"
    name="location"
    value={location}
    onChange={(e) => {
      setLocation(e.target.value);
      setLocationError('');
    }}
    className={`block w-full h-16 px-2.5 pb-2.5 pt-4 text-sm text-gray-900 bg-transparent rounded-lg border-2 border-solid border-gray-700 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer ${locationError ? 'border-red-500' : ''}`}
    placeholder=" "
  />
  <label
    htmlFor="location"
    className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-2"
  >
    Location
  </label>
</div>


          {locationError && <p className="text-red-500 text-sm">{locationError}</p>}
        </div>

          

            <div className="flex flex-wrap -mx-4">
          


             
            </div>

           </div>

            <button
              className="flex w-4/5 md:w-524 h-16 md:h-61 px-4 md:px-12 justify-center items-center bg-black text-white mt-4 mx-auto my-auto rounded-lg"
              onClick={handleSubmit}
              disabled={isSubmitting} // Disable the button based on the state
            >
              {isSubmitting ? 'Submitting.....' : 'Submit'}
            </button>
            
          </div>
        </div>
      </div>
    </div>
  );
};



