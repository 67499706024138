// // apiService.js
import axios from "axios";
import { useQueryClient } from '@tanstack/react-query';
import config from "../config";

export const API_BASE_URL = config.baseURL ; 



const apiService = {


  get_dashboard_data: async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/admin/dashboard `);
      return response.data;
    } catch (error) {
      console.error('Error fetching users:', error);
      throw error;
    }
  },
  

// ------------------------------------------------------------------- users page apis start ----------------------------------------------------------------------------------

  getUsers: async (skipNo , takeNo ,filtervalue,status) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/admin/users?skip=${skipNo}&take=${takeNo}&keyword=${filtervalue}&status=${status} `);
      return response.data;
    } catch (error) {
      console.error('Error fetching users:', error);
      throw error;
    }
  },

  // editUser: async (userData) => {
  //   try {
   
  //     const response = await axios.put(`${API_BASE_URL}/admin/update-userProfile/${userData.id}`, userData);
      
  //     return response.data;
  //   } catch (error) {
  //     throw error;
  //   }
  // },
  editUser: async (userData) => {
    try {
      const formData = new FormData();
      Object.keys(userData).forEach((key) => {
        formData.append(key, userData[key]); });
      const response = await axios.put(`${API_BASE_URL}/admin/update-userProfile/${userData.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },


addUser: async (userData) => {
  try {
 
    const response = await axios.post(`${API_BASE_URL}/admin/users`, userData  , {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    } );
    
    return response.data;
  } catch (error) {
    throw error;
  }
},





deleteUser: async (userId) => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/admin/users/${userId}`);
    return response.data;
  } catch (error) {
    throw error.response || error;
  }
},
// -------------------------------------------------------------------- users page apis ENDS ----------------------------------------------------------------------------------

// -------------------------------------------------------------------- Posts page apis start ----------------------------------------------------------------------------------

  getposts: async (skipNo , takeNo,filtervalue) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/admin/posts?skip=${skipNo}&take=${takeNo}&keyword=${filtervalue}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching users:', error);
      throw error;
    }
  },
  editposts: async (userData) => {
    try {
      const formData = new FormData();
      // Append user data to the FormData object
      Object.keys(userData).forEach((key) => {
        formData.append(key, userData[key]);
      });
      const response = await axios.put(`${API_BASE_URL}/admin/posts/${userData.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },


addPosts: async (userData) => {
  try {
 
    const response = await axios.post(`${API_BASE_URL}/admin/posts`, userData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    
    return response.data;
  } catch (error) {
    throw error;
  }
},
deletePosts: async (userId) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/admin/deletepost/${userId}`);
    return response.data;
  } catch (error) {
    throw error.response || error;
  }
},

get_Post_details: async (PostId ) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/admin/getPostById/${PostId} `);
    return response.data;
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
},




// -------------------------------------------------------------------- Posts page apis END ----------------------------------------------------------------------------------



// -------------------------------------------------------------------- Properties page apis Start ----------------------------------------------------------------------------------

  get_manage_properties: async (skipNo , takeNo) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/admin/house?skip=${skipNo}&take=${takeNo} `);
      return response.data;
    } catch (error) {
      console.error('Error fetching users:', error);
      throw error;
    }
  },
 
  addProperty: async (userData) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/admin/house`, userData,
        // headers: {
        //   'Content-Type': 'multipart/form-data',
        // },
      );
      
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  delete_Manage_properties: async (userId) => {
    try {
      const response = await axios.delete(`${API_BASE_URL}/admin/house/${userId}`);
      return response.data;
    } catch (error) {
      throw error.response || error;
    }
  },
  editproperty : async (propertyData,userData) => {
    try {
      const response = await axios.put(`${API_BASE_URL}/admin/house/${userData.id}`, propertyData,
        // headers: {
        //   'Content-Type': 'multipart/form-data',
        // },
      );
      
      return response.data;
    } catch (error) {
      throw error;
    }
  },

// -------------------------------------------------------------------- Properties page apis End ----------------------------------------------------------------------------------



  //------------------------------------------------------------------ Support page api START--------------------------------------------------------------------------------------------------------------------
  get_support_requests: async (skipNo, takeNo,filterValue) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/admin/support-request?skip=${skipNo}&take=${takeNo}&keyword=${filterValue} `);
      return response.data;
    } catch (error) {
      console.error('Error fetching users:', error);
      throw error;
    }
  },
  get_support_request_view: async (requestId ) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/admin/getRequestById/${requestId} `);
      return response.data;
    } catch (error) {
      console.error('Error fetching users:', error);
      throw error;
    }
  },

    updatestatus: async (supportId,statusData) => {
      try {
    console.log('Updating status',supportId,statusData)
        const response = await axios.post(`${API_BASE_URL}/admin/updateRequestStatus/${supportId}`, {status:statusData});
        
        return response.data;
      } catch (error) {
        throw error;
      }
    },

    deleteSupportRequest: async (userId) => {
      try {
        const response = await axios.delete(`${API_BASE_URL}/admin/support-request/${userId}`);
        return response.data;
      } catch (error) {
        throw error.response || error;
      }
    },



  //------------------------------------------------------------------ Support page api START--------------------------------------------------------------------------------------------------------------------


  //------------------------------------------------------------------ Report request page api START--------------------------------------------------------------------------------------------------------------------

  get_user_report_request: async (skipNo,takeNo,status) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/admin/user_reports?skip=${skipNo}&take=${takeNo}&status=${status}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching users:', error);
      throw error;
    }
  },
  get_post_report_request: async (skipNo,takeNo) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/admin/post_reports?skip=${skipNo}&take=${takeNo}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching users:', error);
      throw error;
    }
  },





  delete_post_req: async (reportId,data) => {
    try {
      const response = await axios.put(`${API_BASE_URL}/admin/reportposts/${reportId}`,data);
      return response.data;
    } catch (error) {
      throw error.response || error;
    }
  },

  block_user_req: async (reportId,Data) => {
    try {
      // const type='block';
      const response = await axios.put(`${API_BASE_URL}/admin/reportusers/${reportId}`,Data);
      return response.data;
    } catch (error) {
      throw error.response || error;
    }
  },

  activate_user: async (reportId) => {
    try {
      // const type='block';
      const response = await axios.put(`${API_BASE_URL}/admin/activateusers/${reportId}`);
      return response.data;
    } catch (error) {
      throw error.response || error;
    }
  },

  //------------------------------------------------------------------ Report request page api START--------------------------------------------------------------------------------------------------------------------


  fetchLocationAutocomplete: async (query) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/location/autocomplete-area?place=${query}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getNftOwner: async (skipNo , takeNo , filterValue) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/admin/nft-owners?skip=${skipNo}&take=${takeNo}&keyword=${filterValue} `);
      return response.data;
    } catch (error) {
      console.error('Error fetching users:', error);
      throw error;
    }
  },

  deleteNftUser: async (nftOwnerId) => {
    try {
      const response = await axios.delete(`${API_BASE_URL}/admin/nft-owner/${nftOwnerId}`);
      return response.data;
    } catch (error) {
      throw error.response || error;
    }
  },


  addNftOwners: async (userData) => {
    try {
   
      const response = await axios.post(`${API_BASE_URL}/admin/nft-owner`, userData  ,
        //  {
      //   headers: {
      //     'Content-Type': 'multipart/form-data',
      //   },
      // } 
      );
      
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  addMultipleUsers: (formData) => {
    return axios.post(`${API_BASE_URL}/admin/csv`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },



};



export default apiService;


