import React, { useEffect, useState } from 'react'
import flag from '../../../../assets/images/flag.svg'
import delete_icon from '../../../../assets/images/delete_icon.svg'
import post_img from '../../../../assets/images/post-img.png'
import apiService from '../../../../services/apiservice'
import {
    useQuery,
    useMutation,
    useQueryClient,
    QueryClient,
    QueryClientProvider,
  } from '@tanstack/react-query'
import Modal from '../../../common/modal/modal'
import { DeleteModal } from '../../../common/modal/deleteModal/deleteModal'
import prev_button from '../../../../assets/images/prevbutton.svg';
import next_button from '../../../../assets/images/nextbutton.svg';
import { UserReportModal } from '../../../common/modal/ReportRequest/userReport'
import Loader from '../../../common/loader/loader'



const PostReport = () => {


  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [FlagModalOpen, setFlagModalOpen] = useState(false);

  const [selectedUserForDelete, setSelectedUserForDelete] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(10);
    const [loading, setLoading] = useState(true)



    const openFlagModal = (user) => {
      setSelectedUserForDelete(user);
      setFlagModalOpen(true);
    };
    
    const closeFlagModal = () => {
      setSelectedUserForDelete(null);
      setFlagModalOpen(false);
    };


  const openDeleteConfirmation = (user) => {
    setSelectedUserForDelete(user);
    setDeleteConfirmationOpen(true);
  };
  
  const closeDeleteConfirmation = () => {
    setSelectedUserForDelete(null);
    setDeleteConfirmationOpen(false);
  };

    const fetch_user_post = async () => {
        try {
          setLoading(true); 

          const users = await apiService.get_post_report_request(skip,take);  
          setTotalPages(Math.ceil(users.totalCount / take));
    console.log('here is',users)
          return users
        } catch (error) {
        }finally{
          setLoading(false)
        }
      };
     
      const query = useQuery({
        queryKey: ['PostReport'],
        queryFn: fetch_user_post,
      });







      useEffect(() => {
        query.refetch();
      }, [skip]);
      
      
      //---------------------------------------------------------------------------- pagination component starts here -----------------------------------------------------------

      const Pagination = ({ currentPage, setCurrentPage, totalPages }) => {
    
    
        const goToPage = (page) => {
          console.log('page value111,',page, 'here si take val',take)
      
          setCurrentPage(page);
    
      console.log((page - 1) * take);
      setSkip((page - 1) * take);
      console.log('afterupdate,',skip, 'here si take val',take)
      query.refetch();
        };
      
        const handlePrevClick = () => {
          if (currentPage > 1) {
            goToPage(currentPage - 1);
          }
        };
      
        const handleNextClick = () => {
          if (currentPage < totalPages) {
            goToPage(currentPage + 1);
          }
        };
        const generatePageNumbers = () => {
          const pageNumbers = [];
          const maxPageButtons = 3; // Adjust this value to change the number of displayed pagess
          let startPage = Math.max(1, currentPage - Math.floor(maxPageButtons / 2));
          let endPage = Math.min(totalPages, startPage + maxPageButtons - 1);
          if (endPage - startPage + 1 < maxPageButtons) {
            startPage = Math.max(1, endPage - maxPageButtons + 1);
          }
          for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
          }
          return pageNumbers;
        };
        return (
          <div className="flex items-center justify-center pt-15 pb-19 mt-5 mb-3 pagination mobile:bottom-0  mobile:py-5 mobile px-0 ">
            <ul className="flex list-none">
            <li
          className={`w-12 h-12 flex items-center justify-center cursor-pointer ${
            currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          onClick={handlePrevClick}
        >
                <img src={prev_button} alt="Previous Page" />
              </li>
              {generatePageNumbers().map((page) => (
                <li
                  key={page}
                  className={`ml-2 w-12 h-12 border-2 border-solid border-gray-300 rounded-full text-gray-500 text-lg flex items-center justify-center cursor-pointer ${
                    page === currentPage ? 'bg-black shadow-md border-none text-white' : ''
                  }`}
                  onClick={() => goToPage(page)}
                >
                  {page}
                </li>
              ))}
              <li
          className={`ml-2 w-12 h-12 flex items-center justify-center cursor-pointer ${
            currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          onClick={handleNextClick}
        >
                <img src={next_button} alt="Next Page" />
              </li>
            </ul>
          </div>
        );
      };
//---------------------------------------------------------------------------- pagination component end here -----------------------------------------------------------


  return (
    <div>
         <div>
    
    
         <Modal
  isOpen={deleteConfirmationOpen}
  onClose={closeDeleteConfirmation}
  title="Delete User"
  
  content={
    <DeleteModal  type={'post-report'}  Data={selectedUserForDelete} onClose={closeDeleteConfirmation} />
  }
/>
<Modal
  isOpen={FlagModalOpen}
  onClose={closeFlagModal}
  title="Delete User"
  
  content={
    <DeleteModal   type={'FlagPost'}  Data={selectedUserForDelete} onClose={closeFlagModal} />
  }
/>


{loading ? (
      <Loader />
    ) : (
    <div className="">
        <div className="RightSideDiv  pr-5">
        

          <div className="right-side-inner p-30 float-left w-full">
  <div className="user-top mb-15">

  </div>
  <div className="table-main bg-white border-radius-15 overflow-hidden block w-full    overflow-y-auto     mobile:max-h-[calc(100vh-350px)]    max-h-[calc(100vh-290px)]     " >
    <table className="table w-full">
      <thead className='text-left'>
        <tr>
          <th scope="col" className='p-4 font-medium'  >Post details</th>
          <th scope="col" className='p-4 font-medium'  >Reported by</th>

          <th scope="col" className='p-5 font-medium' >Reason </th>
         
          <th scope="col" className='p-4 font-medium' >Status</th>
          <th scope="col" className='p-4 font-medium' >Action</th>
        </tr>
      </thead>
      <tbody>
      {query?.data?.post?.map((request,index ) => (
        <tr    key={request.id} className={` ${index % 2 === 0 ? 'bg-custom-gray' : ''}`}>
         
         {/* Post details starts*/}
         <td className=" flex items-center pr-4 mt-4">
  <span className="user-img inline-block w-10 h-10 bg-ddd border-radius-50 rounded-full overflow-hidden">
    <img src={request?.post?.image || post_img} alt="" className="w-full h-full object-cover" />
  </span>
  <div className="user-info inline-block flex flex-col pl-4 ">
    <h3 className="text-15px text-22242C mb-1  ">{request?.post?.content || '--'}</h3>
  </div>
</td>

         {/* Post details ends*/}
  
        {/* reported by starts*/}
        <td className='pr-12'>
        <div className='flex items-center'>

          <span className="user-img inline-block w-10 h-10 bg-ddd border-radius-50 rounded-full overflow-hidden mr-2 ">
              <img src={request?.reporter?.imageProfile} alt="" className="w-full h-full object-cover" />
            </span>
            <div className="user-info inline-block    max-w-[120px]  ">
              <h3 className="text-15px text-22242C ">{ request?.reporter?.userName ||'--'}</h3>
              <h4 className="text-xs text-gray-500 line-clamp-1  lg:line-clamp-none  ">{request?.reporter?.email ||'--'}</h4>
            </div>
        </div>
          </td>
         {/* reported by ends*/}

         <td className='text-sm max-w-[80px] p-5 pr-12 lg:max-w-[none] '>{request.reason || 'The Hideaway Cottage'}</td>
         
          <td className='text-sm pr-12' ><button className="status border-radius-50 w-100px text-sm bg-ededef font-weight-500">{ request.status ||'Pending'}</button></td>
          <td className="py-4 flex items-center">
  <button className="w-8 h-auto bg-black text-white rounded-full ml-2" onClick={() => openDeleteConfirmation(request.id)}>
    <img src={delete_icon} alt="Delete Icon" />
  </button>

  {request.status === 'Pending' && (
    <button className="w-8 h-auto bg-2E92D9 text-green-500 rounded-full ml-4 " onClick={() => openFlagModal(request.id)}>
      <img src={flag} alt="Flag Icon" />
    </button>
  )}
</td>

        </tr>

        ))}
      </tbody>
    </table>
  </div>
  <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages} />

</div>



          
        </div>
      </div>
    )}
    </div>
    </div>
  )
}

export default PostReport