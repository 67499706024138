import React, { useEffect, useState } from 'react'
import view_icon from '../../../../assets/images/view (2).svg'
import delete_icon from '../../../../assets/images/delete_icon.svg'
import user_img from '../../../../assets/images/placeholder-image-person-jpg.jpg'
import apiService from '../../../../services/apiservice'
import {
    useQuery,
    useMutation,
    useQueryClient,
    QueryClient,
    QueryClientProvider,
  } from '@tanstack/react-query'
import Modal from '../../../common/modal/modal'
import { UserReportModal } from '../../../common/modal/ReportRequest/userReport'
import prev_button from '../../../../assets/images/prevbutton.svg';
import next_button from '../../../../assets/images/nextbutton.svg';
import Loader from '../../../common/loader/loader'
import { UserReportviewModal } from '../../../common/modal/ReportRequest/userReportView'



export const UsersReport = () => {

    const [userData, setUserData] = useState([]);
    const [loading, setLoading] = useState(true); // New state variable for loading indicator

    const [blockconfirmation, setblockconfirmation] = useState(false);
    const [suspendModal, setsuspendModal] = useState(false);
    const [activateUserModal, setactivateUserModal] = useState(false);

    const [selectedUserFor, setSelectedUserFor] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(10);
    const [filterValue, setFilterValue] = useState('All');
    const [viewModal, setViewModal] = useState(false);
    const [noData, setnoData] = useState(false)



    // const opensuspenduser = (user) => setsuspendModal(true)
    const closesuspenduser = () => setsuspendModal(false)
    const closeActivateuser = () => setactivateUserModal(false)


    const openViewUser = (user) => {
      setSelectedUserFor(user);
      setViewModal(true);
    };
  
    const closeViewUser = () => {
      setSelectedUserFor(null);
      setViewModal(false);
    };


    const opensuspenduser = (user) => {
      setSelectedUserFor(user);

      setsuspendModal(true)
    }
    const openActivateUser = (user) => {
      setSelectedUserFor(user);

      setactivateUserModal(true)
    }

    
    const OpenUserReportModal = (user) => {
      setSelectedUserFor(user);
      setblockconfirmation(true);
    };
    
    const CloseUserReportModal = () => {
      setSelectedUserFor(null);
      setblockconfirmation(false);
    };

    const fetchUsers = async () => {
        try {
          setLoading(true); 

          const users = await apiService.get_user_report_request(skip,take,filterValue);
          setTotalPages(Math.ceil(users.totalCount / take));
          setUserData(users);
          setnoData(false);

          return users
        } catch (error) {
console.log(error , 'rrror')
          if (error.response.data.statusCode === 400) {
            // Show toastr warning for no data found
      setnoData(true)        
          }

        }finally{
          setLoading(false);
        }
      };
     
      const query = useQuery({ queryKey: ['usersReport'], queryFn: fetchUsers, })
      useEffect(() => {
        query.refetch();
      }, [skip ,filterValue]);
      
      const Pagination = ({ currentPage, setCurrentPage, totalPages }) => {
    
    
        const goToPage = (page) => {
          console.log('page value111,',page, 'here si take val',take)
      
          setCurrentPage(page);
      //     setSkip((page*10)-10)
      //     console.log('skip value from gotopage',skip)
      // // fetch_manage_posts()
      //     queryClient.invalidateQueries('manageposts');/
      console.log((page - 1) * take);
      setSkip((page - 1) * take);
      console.log('afterupdate,',skip, 'here si take val',take)
      query.refetch();
        };
      
        const handlePrevClick = () => {
          if (currentPage > 1) {
            goToPage(currentPage - 1);
          }
        };
      
        const handleNextClick = () => {
          if (currentPage < totalPages) {
            goToPage(currentPage + 1);
          }
        };
        const generatePageNumbers = () => {
          const pageNumbers = [];
          const maxPageButtons = 3; // Adjust this value to change the number of displayed pagess
          let startPage = Math.max(1, currentPage - Math.floor(maxPageButtons / 2));
          let endPage = Math.min(totalPages, startPage + maxPageButtons - 1);
          if (endPage - startPage + 1 < maxPageButtons) {
            startPage = Math.max(1, endPage - maxPageButtons + 1);
          }
          for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
          }
          return pageNumbers;
        };
        return (
          <div className="flex items-center justify-center pt-15 pb-19 mt-5 mb-3  pagination reportreq mobile:bottom-0  mobile:py-5 mobile px-0  ">
            <ul className="flex list-none">
            <li
          className={`w-12 h-12 flex items-center justify-center cursor-pointer ${
            currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          onClick={handlePrevClick}
        >
                <img src={prev_button} alt="Previous Page" />
              </li>
              {generatePageNumbers().map((page) => (
                <li
                  key={page}
                  className={`ml-2 w-12 h-12 border-2 border-solid border-gray-300 rounded-full text-gray-500 text-lg flex items-center justify-center cursor-pointer ${
                    page === currentPage ? 'bg-black shadow-md border-none text-white' : ''
                  }`}
                  onClick={() => goToPage(page)}
                >
                  {page}
                </li>
              ))}
              <li
          className={`ml-2 w-12 h-12 flex items-center justify-center cursor-pointer ${
            currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          onClick={handleNextClick}
        >
                <img src={next_button} alt="Next Page" />
              </li>
            </ul>
          </div>
        );
      };



      

  return (
    <div>
    
    {loading ? (
      <Loader />
    ) : (

    
    <div className="">
        <div className="RightSideDiv  pr-5">

        
        <Modal
  isOpen={blockconfirmation}
  onClose={CloseUserReportModal}
  // title="Are you sure you want to block this request"
  
  content={
    <UserReportModal      title="Are you sure you want to block this request"
 type={'userReport'}  Data={selectedUserFor} onClose={CloseUserReportModal} />
  }
/>
<Modal
  isOpen={suspendModal}
  onClose={closesuspenduser}
  // title="Are you sure you want to block this request"
  type={'suspend'}
  content={
    <UserReportModal      title="Are you sure you want to suspend this request"
 type={'suspend'}  Data={selectedUserFor}  onClose={closesuspenduser} />
  }
/>
<Modal
  isOpen={activateUserModal}
  onClose={closeActivateuser}
  // title="Are you sure you want to block this request"
  type={'activate'}
  content={
    <UserReportModal      title="Are you sure you want to Activate this request"
 type={'activate'}  Data={selectedUserFor}  onClose={closeActivateuser} />
  }
/> 


<Modal
              isOpen={viewModal}
              onClose={closeViewUser}
              content={
                <UserReportviewModal
                  title="View User Report"
                  type={'view'}
                  Data={selectedUserFor}
                  onClose={closeViewUser}
                />
              }
            />



          <div className="right-side-inner p-30 float-left w-full">
  <div className="user-top mb-15">
  


    <div className="post-select float-right support-request">
               <select className="post-select-option bg-white border border-blue-200 rounded-md w-40 h-9  px-4 mb-4 styled-select "
                value={filterValue}
  onChange={(e) => setFilterValue(e.target.value)}
   >
              <option>All</option>
              <option value="Pending">Pending</option>
              <option value="Closed">Closed</option>
            </select>
            
          </div>

  </div>


  <div className="table-main bg-white border-radius-15  block w-full    overflow-y-auto   mobile:max-h-[calc(100vh-350px)]    max-h-[calc(100vh-300px)] " >
    <table className="table w-full">
      <thead className='text-left'>
        <tr>
          <th scope="col" className='p-4 font-medium'   >Reported by</th>
          <th scope="col" className='p-4 font-medium'  >Reported to</th>

          <th scope="col" className='p-4 w-[350px] font-medium' >Reason </th>
         
          <th scope="col" className=' font-medium' >Status</th>
          <th scope="col" className='p-4 font-medium w-[10px]' >Action</th>
        </tr>
      </thead>
      <tbody>
      {noData ? (
          <tr>
            <td colSpan="5" className="text-center py-4 text-gray-500">No Data Found</td>
          </tr>
        ) : (

      query?.data?.users?.map((user ,index) => (
        <tr key={user.id} className={` ${index % 2 === 0 ? 'bg-custom-gray' : ''}`}    >

        {/* reported by starts */}

          <td>
            <span className="user-img inline-block w-10 h-10 bg-ddd border-radius-50 rounded-full overflow-hidden mr-2">
              <img src={user?.reporter?.imageProfile||user_img} alt="" className="w-full h-full object-cover" />
            </span>
            <div className="user-info inline-block vertical-align-middle pl-2 w-1/4    mobile:w-3/5 mobile:overflow-hidden md:overflow-hidden md:w-3/5  ">
              <h3 className="text-15px text-22242C ">{ user?.reporter?.userName ||'--'}</h3>
              <h4 className="text-xs text-gray-500   ">{user?.reporter?.email ||'--'}</h4>
            </div>
          </td>
{/* reported by ends */}

{/* reported to starts */}
          <td>
            <span className="user-img inline-block w-10 h-10 bg-ddd border-radius-50 rounded-full overflow-hidden mr-2">
              <img src={user?.reported?.imageProfile||user_img} alt="" className="w-full h-full object-cover" />
            </span>
            <div className="user-info inline-block vertical-align-middle pl-2 w-1/4   mobile:w-3/5 mobile:overflow-hidden md:overflow-hidden md:w-3/5  ">
              <h3 className="text-15px text-22242C ">{ user?.reported?.userName ||'--'}</h3>
              <h4 className="text-xs text-gray-500   ">{user?.reported?.email ||'--'}</h4>
            </div>
          </td>
{/* reported to ends */}


          <td className="pr-2 text-sm line-clamp-3 pt-12 ">{  user.reason || 'The Hideaway Cottage'}</td>
       
          <td className="pr-4 text-sm "><p className="status border-radius-50 w-100px text-8E8EA1 bg-ededef font-weight-500">{ user?.status ||'Pending'}</p></td>
          <td className="py-4  w-[10px]  text-sm">
  { user.days ===null? (
    <>
    <button className="w-16 h-auto bg-yellow-500 text-white rounded-md mr-2 sm:mb-2 mobile:mb-2" onClick={() => openViewUser(user)} >
    View
  </button>
      <button className="w-16 h-auto   bg-blue-400 text-white rounded-md mr-2 sm:mb-2  mobile:mb-2 " onClick={() => OpenUserReportModal(user.id)}>
        Block
      </button>
      <button className="w-16 h-auto bg-red-600  text-white rounded-md " onClick={() => opensuspenduser(user.id)}>
      Suspend
    </button>
    </>
  ) : (
   

    <button className="w-16 h-auto  bg-green-600 text-white rounded-md " onClick={() => openActivateUser(user.id)} >
  Activate
</button>


  )}
</td>

        </tr>

        )))}
      </tbody>
    </table>
  </div>
  <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages} />

</div>



          
        </div>
      </div>
    )}
    </div>
  )
}
