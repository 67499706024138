import React, { useState } from 'react';
import PostReport from '../../layout/report requests/PostReport/PostReport';
import { UsersReport } from '../../layout/report requests/UsersReport/UsersReport';

const Tabs = () => {
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  return (
    <div className=" mx-auto mt-2">
      <div className="flex  border-gray-300">
        <button
          className={`py-2 px-3 border-b-2 ${
            activeTab === 1 ? 'border-blue-500 text-blue-500' : 'border-transparent text-gray-500'
          }`}
          onClick={() => handleTabClick(1)}
        >
          Users
        </button>
        <button
          className={`py-2 px-4 border-b-2 ${
            activeTab === 2 ? 'border-blue-500 text-blue-500' : 'border-transparent text-gray-500'
          }`}
          onClick={() => handleTabClick(2)}
        >
         Posts
        </button>
       
      </div>
      <div >
        {activeTab === 1 && <div> <UsersReport/>  </div>}
        {activeTab === 2 && <div>  <PostReport/> </div>}
       
      </div>
    </div>
  );
};

export default Tabs;
