







import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import apiService from '../../../../services/apiservice';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useQueryClient } from '@tanstack/react-query';

const validationSchema = Yup.object().shape({
  suspendReason: Yup.number()
    .typeError('Number of days must be a valid number')
    .required('Number of days is required')
    .min(0, 'Number of days must not be negative'),

});

export const UserReportModal = ({ isOpen, onClose, title, Data, type }) => {
  const queryClient = useQueryClient();
  const [isConfirming, setIsConfirming] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false); // State to manage the submit button

  const closeDeleteConfirmation = () => {
    onClose();
  };

  const handleSubmit = async (values) => {
    try {
      setIsSubmitting(true);
      let result;

      if (type === 'userReport') {
        const dataForUserReport = { type: 'block' };
        result = await apiService.block_user_req(Data, dataForUserReport);
      } else if (type === 'suspend') {
        const dataForSuspend = {
          days: values.suspendReason,
        };
        result = await apiService.block_user_req(Data, dataForSuspend);
      } else if (type === 'activate') {
        result = await apiService.activate_user(Data);
      }

      if (result && result.statusCode === 200) {
        queryClient.invalidateQueries('usersReport');
        closeDeleteConfirmation();
        toast.success(result.message);
      } else {
        console.error('Error:', result);
        toast.error(result.message || 'Error');
      }
    } catch (error) {
      console.error('Unexpected error:', error);
      toast.error('Unexpected error');
    }
  };

  return (
    <div className="text-gray-700 flex flex-col items-center">
      <p className="mb-8 text-xl text-left font-bold">{title}</p>
      <Formik
        initialValues={{
          suspendReason: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({ touched, errors }) => (
          <Form>
            {type === 'suspend' && (
              <div className="mb-4">
                <label htmlFor="suspendReason" className="text-lg">
                  Number of days you want to suspend:
                </label>
                <div className="w-full px-4 mb-4">
                  <Field
                    type="number"
                    name="suspendReason"
                    min="0"
                    className={`w-full h-16 px-4 rounded-lg border-2 border-solid border-gray-400 ${
                      touched.suspendReason && errors.suspendReason ? 'border-red-500' : ''
                    }`}
                    placeholder="days"
                  />
                  
                  <ErrorMessage
                    name="suspendReason"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>
              </div>
            )}
                   <div className="flex flex-col sm:flex-row sm:space-x-6">

            {(type === 'activate' || type === 'userReport') && (
              <button
                // type="submit"
                onClick={handleSubmit}
                className="bg-red-500 text-white w-full sm:w-auto px-16 py-4 rounded-md mb-4 sm:mb-0 hover:bg-red-600 focus:outline-none focus:ring focus:border-blue-300"
              >
                Yes
              </button>
            )}

            {type === 'suspend' && (
              <button
                type="submit"
                disabled={isSubmitting}
                className="bg-red-500 text-white w-full sm:w-auto px-16 py-4 rounded-md mb-4 sm:mb-0 hover:bg-red-600 focus:outline-none focus:ring focus:border-blue-300"
              >
                Yes
              </button>
            )}

            <button
              type="button"
              className="bg-blue-500 text-white w-full sm:w-auto px-16 py-4 rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300"
              onClick={closeDeleteConfirmation}
            >
              No
            </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};






