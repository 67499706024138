// axiosinterceptor.js


import axios from 'axios';

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('Authtoken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    if (error.response.status === 400) {
      window.location.href = '/';
    }
    if (error.response.status === 401 && error.response.data.error === 'Token is required') {
      window.location.href = '/';
    }

    return Promise.reject(error);
  }
);

export default axios;
