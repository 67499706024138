


import React, { useEffect, useRef, useState } from 'react';
import add_pic from '../../../../assets/images/add-pic.svg';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import apiService from '../../../../services/apiservice';
import { toast, ToastContainer } from 'react-toastify';
import closeIcon from '../../../../assets/images/Cross.svg';
import cameraicon from '../../../../assets/images/camera.svg';
import { MultiSelect } from 'primereact/multiselect';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';


import 'react-toastify/dist/ReactToastify.css';
import { useQueryClient } from '@tanstack/react-query';
import { ImageCropperModal } from '../cropperModal/ImageCropperModal';



export const ManageUsersModal = ({ isOpen, onClose, type, userData }) => {
  const queryKeyForUsers = ['manageuser']; // Adjust the query key structure
  const queryClient = useQueryClient();

  const [imagePreview, setImagePreview] = useState(userData?.imageProfile || null);
  
  const [phone, setPhone] = useState(userData?.phoneNumber || '');
  const [firstname, setFirstName] = useState(userData?.firstName || '');
  const [lastname, setLastName] = useState(userData?.lastName || '');
  const [email, setEmail] = useState(userData?.email || '');
  const [isSubmitting, setIsSubmitting] = useState(false); // Add state for button disabled state
  const [selectedRole, setSelectedRole] = useState([]); // New state for selected role

  const [phoneError, setPhoneError] = useState('');
  const [firstnameError, setFirstNameError] = useState('');
  const [lastnameError, setLastNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [imageError, setImageError] = useState('');
  const [roleError, setRoleError] = useState('')
  const [image, setImage] = useState(null); // Add state for image file

  const messagesEndRef = useRef(null);


  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.addEventListener('DOMNodeInserted', event => {
        const { currentTarget: target } = event;
        target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
      });
    }
  };


  useEffect(() => {
    scrollToBottom(); 
    if (isOpen) {
      scrollToBottom();
    }
  }, [isOpen]);

  
  const validateEmail = () => {
    // Basic email format validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError('Enter a valid email address');
      
      return false;
    }
    setEmailError('');
    return true;
  };

  const handlePhoneChange = (value) => {
    setPhone(value);
  };
  

  // const handleImageChange = (e) => {
  //   setImage(e.target.files[0]);
  // };

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    setImage(selectedImage);
    if (!selectedImage) {
      setImageError('Image is required');
      return;
    }
    // Create a preview URL for the selected image
    const previewURL = URL.createObjectURL(selectedImage);
    
    setImagePreview(previewURL);
    setImageError('');

  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true); // Set disabled state to true

      let check;
      
      if (type == 'add') {
        check = !phone || !firstname || !lastname || !email || selectedRole.length === 0 || (!croppedImageFile  && type === 'add');
      }
      else {
        check = !phone || !firstname || !lastname || !email;

      }
      if (check) {
        if (!phone) setPhoneError('Phone number is required');
        if (!firstname) setFirstNameError('First name is required');
        if (!lastname) setLastNameError('Last name is required');
        if (!email) setEmailError('Email is required');
        if (selectedRole.length === 0) setRoleError('Role is required');
        if (!croppedImageFile  && type === 'add') setImageError('Image is required');

        // if (type === 'add' && !image) {

        //   setImageError('Image is required');
        //   setIsSubmitting(false);
        //   ;
        // }
       

        toast.error('Please fill in all details', { position: toast.POSITION.TOP_RIGHT });
        setIsSubmitting(false); // Reset disabled state
        return; // Exit function early
      }
      if (!validateEmail()) {
        setIsSubmitting(false); // Reset disabled state
        return;
      }


      if (type === 'add' && selectedRole.length == 0) {

        setRoleError('Role is required');
        setIsSubmitting(false);
        return;
      }

      const rolesArray = [selectedRole];
      if (type === 'edit') {
        const updatedUserData = {
          id: userData.id,
          firstName: firstname,
          lastName: lastname,
          phoneNumber: phone,
          image: croppedImageFile
          // Add other properties as needed
        };

        await apiService.editUser(updatedUserData);
        console.log('User updated successfully');
        onClose();

        toast.success('User edited successfully', { position: toast.POSITION.TOP_RIGHT });
        queryClient.invalidateQueries(queryKeyForUsers);

      } else if (type === 'add') {
        // Call the addUser API
        await apiService.addUser({
          phoneNumber: `+${phone}`,
          firstName: firstname,
          lastName: lastname,
          email,
          image: croppedImageFile,
          roles: selectedRole

        });
        onClose();
        toast.success('User Created successfully', { position: toast.POSITION.TOP_RIGHT });

        setIsSubmitting(false); // Set disabled state to true

        queryClient.invalidateQueries(queryKeyForUsers);

      }

      // Close the modal or perform other actions
      onClose();
    } catch (error) {
      if (error.response) {
       
        const status = error.response.status;
        const data = error.response.data;

        if (status === 400) {
          toast.error(` ${data.message}`, { position: toast.POSITION.TOP_RIGHT });
        } else if (status === 500) {
          toast.error(`Internal Server Error: ${data.message}`, { position: toast.POSITION.TOP_RIGHT });
        } else {
          toast.error(`Unexpected Error: ${data.message || 'Error updating user'}`, { position: toast.POSITION.TOP_RIGHT });
        }
      } else if (error.request) {
        // The request was made but no response was received
        toast.error('No response received from the server', { position: toast.POSITION.TOP_RIGHT });
      } else {
        // Something happened in setting up the request that triggered an Error
        toast.error(`Error: ${error.message}`, { position: toast.POSITION.TOP_RIGHT });
      }

      console.error('Error updating user:', error);
      setIsSubmitting(false);
    }
  };


  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();

    const droppedFiles = e.dataTransfer.files;

    if (droppedFiles.length > 0) {
      const selectedImage = droppedFiles[0];
      setImage(selectedImage);

      const previewURL = URL.createObjectURL(selectedImage);
      setImagePreview(previewURL);
    }
  };
  const blobToDataURL = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const [croppedImageFile, setCroppedImageFile] = useState(null);
  const [croppedImagePreview, setCroppedImagePreview] = useState(null);



const handleCroppedImage = (croppedImageBlob) => {
  
  setCroppedImagePreview(croppedImageBlob);
 fetch(croppedImageBlob)
 .then((response) => response.blob())
 .then((blob) => {
   // Create a new File object from the blob
   const croppedImageFile = new File([blob], 'cropped-image.png', { type: 'image/png' });
   console.log('Cropped Image File:', croppedImageFile);

   // Update the state with the cropped image File
   setCroppedImageFile(croppedImageFile);
  //  generateThumbnail(croppedImageFile);
  //  calculateImageDimensions(croppedImageFile);

 })
 .catch((error) => {
   console.error('Error fetching or converting Blob URL:', error);
 });


};



  return (
    <div   className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div  className="modal-dialog max-w-3x1">
        <div className="modal-content rounded-10 border-0">
          <div className="modal-body p-30 ">
          {image && (
            <ImageCropperModal imageUrl={imagePreview} onClose={() => setImage(false)}   onCropComplete={(croppedImageBlob) => handleCroppedImage(croppedImageBlob)} />
)}
            <div className="flex justify-between items-center  mb-4">
              {/* Close button on the left */}
              <button onClick={onClose} className="cursor-pointer">
              </button>
              {/* Title centered */}
              <h3 className="text-center text-2xl font-semibold">{type === 'edit' ? 'Edit User' : 'Add User'}</h3>
              {/* Empty div to maintain spacing */}
              <div></div>
            </div>
            <div ref={messagesEndRef} className='mobile:overflow-y-auto  mobile:overflow-x-hidden mobile:pr-3  mobile:h-screen  relative mobile:max-h-[calc(100vh-285px)] pr-2  overflow-y-auto overflow-x-hidden  max-h-[calc(100vh-225px)] '  >

           


              <div className="add-user-pic w-200 h-200 m-7 bg-F5F5F5 rounded-full">

                <label
                  id="dropcontainer"
                  className="drop-container flex bg-slate-300 w-44 h-44 flex-col justify-center rounded-full text-black cursor-pointer p-0 m-auto items-center"
                  onDragOver={(e) => handleDragOver(e)}
                  onDrop={(e) => handleDrop(e)}
                >


                  {/* {imagePreview ? (
                    <img src={imagePreview} alt="Image Preview" className="preview-img" />
                  ) : (
                    <img src={add_pic} alt="Add Photo" className='' />
                  )} */}


                  {croppedImagePreview ? (
  <img src={croppedImagePreview} alt="Cropped Image Preview" className="preview-img" />
) : imagePreview ? (
  <img src={imagePreview} alt="Image Preview" className="preview-img" />
) : (
  <img src={add_pic} alt="Add Photo" />
)}

                  <div className={`relative top-${imagePreview ? '8' : '0'} left-24 bg-black   w-10 h-10 rounded-full flex items-center justify-center`}>
                    <img src={cameraicon} />
                  </div>

                  <input type="file" id="images" accept="image/*" required="" className="hidden" onChange={handleImageChange} onDragOver={(e) => handleDragOver(e)}
                    onDrop={(e) => handleDrop(e)} />
                  <h3 className="text-17 font-semibold -mt-6 ">Add Photo</h3>
                  <h5 className="text-xs font-normal text-909090 mb-2">or drag and drop</h5>

                </label>
                {imageError && <p className="text-red-500 text-sm text-center mx-auto ">{imageError}</p>}

              </div>



              {/* <div className="flex flex-wrap -mx-4">
                <div className="w-full sm:w-1/2 px-4 mb-4">
                  <input
                    type="text"
                    name="firstname"
                    value={firstname}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                      setFirstNameError(''); // Clear error message when user starts typing
                    }}
                    className={`w-full h-16 px-4 rounded-lg border-2 border-solid border-gray-700 text-base sm:text-sm md:text-sm lg:text-sm xl:text-sm ${firstnameError ? 'border-red-500' : ''}`}
                    placeholder="First Name"
                  />
                  {firstnameError && <p className="text-red-500 text-sm">{firstnameError}</p>}
                </div>

                <div className="w-full sm:w-1/2 px-4 mb-4">
                  <input
                    type="text"
                    name="lastname"
                    value={lastname}
                    onChange={(e) => {
                      setLastName(e.target.value);
                      setLastNameError('');
                    }}
                    className={`w-full h-16 px-4 rounded-lg border-2 border-solid border-gray-700  text-base sm:text-sm md:text-sm lg:text-sm xl:text-sm ${lastnameError ? 'border-red-500' : ''
                      }`}
                    placeholder="Last Name"
                  />
                  {lastnameError && <p className="text-red-500 text-sm">{lastnameError}</p>}
                </div>
              </div> */}
              <div className="flex flex-wrap -mx-4">
  <div className="relative w-full sm:w-1/2 px-4 mb-6">
    <input
      type="text"
      name="firstname"
      id="firstname"
      value={firstname}
      onChange={(e) => {
        setFirstName(e.target.value);
        setFirstNameError(''); // Clear error message when user starts typing
      }}
      className={`block w-full h-16 px-2.5 pb-2.5 pt-4 text-sm text-gray-900 bg-transparent rounded-lg border-2 border-solid border-gray-700 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer ${firstnameError ? 'border-red-500' : ''}`}
      placeholder=" "
    />
    <label
      htmlFor="firstname"
      className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-6"
    >
      First Name
    </label>
    {firstnameError && <p className="text-red-500 text-sm  absolute -bottom-5">{firstnameError}</p>}
  </div>

  <div className="relative w-full sm:w-1/2 px-4 mb-6">
    <input
      type="text"
      name="lastname"
      id="lastname"
      value={lastname}
      onChange={(e) => {
        setLastName(e.target.value);
        setLastNameError('');
      }}
      className={`block w-full h-16 px-2.5 pb-2.5 pt-4 text-sm text-gray-900 bg-transparent rounded-lg border-2 border-solid border-gray-700 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer ${lastnameError ? 'border-red-500' : ''}`}
      placeholder=" "
    />
    <label
      htmlFor="lastname"
      className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-6"
    >
      Last Name
    </label>
    {lastnameError && <p className="text-red-500 text-sm absolute -bottom-5">{lastnameError}</p>}
  </div>
</div>


              {/* <div className="flex flex-wrap -mx-4">
  <div className="relative w-full sm:w-1/2 px-4 mb-4">
    <input
      type="text"
      name="firstname"
      id="firstname"
      value={firstname}
      onChange={(e) => {
        setFirstName(e.target.value);
        setFirstNameError(''); // Clear error message when user starts typing
      }}
      className={`block w-full h-16 px-2.5 pb-2.5 pt-4 text-sm text-gray-900 bg-transparent rounded-lg border-2 border-solid border-gray-700 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer ${firstnameError ? 'border-red-500' : ''}`}
      placeholder=" "
    />
    <label
      htmlFor="firstname"
      className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-6"
    >
      First Name
    </label>
    {firstnameError && <p className="text-red-500 text-sm  ">{firstnameError}</p>}
  </div>

  <div className="relative w-full sm:w-1/2 px-4 mb-4">
    <input
      type="text"
      name="lastname"
      id="lastname"
      value={lastname}
      onChange={(e) => {
        setLastName(e.target.value);
        setLastNameError('');
      }}
      className={`block w-full h-16 px-2.5 pb-2.5 pt-4 text-sm text-gray-900 bg-transparent rounded-lg border-2 border-solid border-gray-700 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer ${lastnameError ? 'border-red-500' : ''}`}
      placeholder=" "
    />
    <label
      htmlFor="lastname"
      className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-6"
    >
      Last Name
    </label>
    {lastnameError && <p className="text-red-500 text-sm">{lastnameError}</p>}
  </div>
</div> */}





              <div className="flex flex-wrap -mx-4">
                <div className="w-full sm:w-1/2 px-4 mb-6">
                  <div style={{ border: `2px solid ${phoneError ? 'red' : '#455A64'}`, borderRadius: '8px' }}>

                    <PhoneInput
                      // containerStyle={{ border: '2px solid #455A64', borderRadius: '8px' }}
                      inputStyle={{ width: ' 100%', border: 'none', outline: 'none' }}
                      country={'us'}
                      placeholder='phone number'
                      value={phone}
                      enableSearch
                      onChange={(value) => {
                        setPhone(value);
                        setPhoneError('');
                      }}
                    />
                  </div>
                  {phoneError && <p className="text-red-500 text-sm absolute ">{phoneError}</p>}
                </div>
                {type === 'edit' && (

               

                  <div className="relative w-full sm:w-1/2 px-4 mb-4 ">
  <input
    type="email"
    name="email"
    value={email}
    readOnly
    disabled
    onChange={(e) => setEmail(e.target.value)}
    className={`block w-full h-16 px-2.5 pb-2.5 pt-4 text-sm text-gray-900 bg-transparent rounded-lg border-2 border-solid border-gray-700 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer`}
    placeholder=" "
  />
  <label
    htmlFor="email"
    className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-6"
  >
    Email
  </label>
</div>

                  
                  )}

                {/* Dropdown for selecting role - only visible in 'add' condition */}
                {type === 'add' && (
                  <div className="w-full sm:w-1/2 px-4 mb-6">
                    <MultiSelect
                      id="role"
                      name="role"
                      value={selectedRole}
                      placeholder='select role'
                      options={[
                        { label: 'Owner', value: 'owner' },
                        { label: 'Investor', value: 'investor' },
                        { label: 'Buyer', value: 'buyer' },
                      ]}
                      onChange={(e) => {
                        setSelectedRole(e.target.value);
                        setRoleError('');
                      }}
                      className={`w-full h-16 px-4 rounded-lg border-2 border-solid border-gray-700  text-base sm:text-sm md:text-sm lg:text-sm xl:text-sm ${roleError ? 'border-red-500' : ''
                        }`}
                      panelStyle={{ backgroundColor: '#ffff' ,border:'1px solid #ddd' ,padding :"10px"}} // Set the background color of the dropdown panel
                      style={{ lineHeight: '56px' }}
                    >
                    </MultiSelect>
                    {roleError && <p className="text-red-500 text-sm  absolute">{roleError}</p>}
                  </div>
                )}
              </div>





              <div className="flex flex-wrap -mx-4">

                {type === 'add' && (



                  <div className="relative w-full sm:w-1/2 px-4 mb-4">
  <input
    type="email"
    name="email"
    value={email}
    onChange={(e) => {
      setEmail(e.target.value);
      setEmailError('');
    }}
    className={`block w-full h-16 px-2.5 pb-2.5 pt-4 text-sm text-gray-900 bg-transparent rounded-lg border-2 border-solid border-gray-700 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer ${emailError ? 'border-red-500' : ''}`}
    placeholder=" "
  />
  <label
    htmlFor="email"
    className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2  origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-6"
  >
    Email
  </label>
  {emailError && <p className="text-red-500 text-sm mt-2 absolute -bottom-6">{emailError}</p>}
</div>



                )}
              </div>

              <div className="flex flex-wrap -mx-4">
              
              {type === 'edit' && (
  <div className="w-full sm:w-1/2 px-4 mb-4">
    <input
      type="text"
      name="roles"
      value={userData?.roles.join(', ') || ''}
      readOnly
      disabled
      className="w-full h-16 px-4 rounded-lg border-2 border-solid bg-gray-200 border-gray-200 cursor-not-allowed text-base sm:text-sm md:text-sm lg:text-sm xl:text-sm"
      placeholder="Roles"
    />
  </div>
)}
</div>










            </div>
            <button
              className="flex w-4/5 md:w-524 h-16 md:h-61 px-4 md:px-12 justify-center mt-4 items-center bg-black text-white mx-auto my-auto rounded-lg"
              onClick={handleSubmit}
              disabled={isSubmitting} // Disable the button based on the state
            >
              {isSubmitting ? 'Submitting.....' : 'Submit'}
            </button>

          </div>
        </div>
      </div>
    </div>
  );
};



