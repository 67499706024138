import React, { useEffect, useState } from 'react'
import apiService from '../../../../services/apiservice';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useQueryClient } from '@tanstack/react-query';
import add_pic from '../../../../assets/images/add-pic.svg';

export const PropertiesModal = ({type,onClose, userData}) => {
    const queryClient = useQueryClient();
    const [isSubmitting, setIsSubmitting] = useState(false); // Add state for button disabled state
    
console.log('datawe gettiting on user edit ',userData)
    
const [formData, setFormData] = useState({
    title: userData?.title || '',
    bathrooms: userData?.bathrooms|| 0,
    bedrooms:userData?.bedrooms || 0,
    built: userData?.built || 0,
    notes:  userData?.notes || '',
    price: userData?.price|| 0,
    cooling:  userData?.cooling||'',
    description: userData?.description||'',
    houseType: userData?.houseType||'',
    lot: userData?.lot||'',
    sqft: userData?.sqft|| 0,
    stories: userData?.stories || 0,
    type: userData?.type || '',
    location: {
      number:  userData?.location.number || '',
      street: userData?.location.street ||  '',
      state:  userData?.location.state ||'',
      city: userData?.location.city|| '',
      country: userData?.location.country||'',
      zip_or_postcode: userData?.location.zip_or_postcode||0,
    },
  });


  



const handleChange = (e) => {
    const { name, value } = e.target;
  
    if (name.startsWith('location.')) {
      const locationField = name.split('.')[1]; 
      setFormData((prevFormData) => ({
        ...prevFormData,
        location: {
          ...prevFormData.location,
          [locationField]: value,
        },
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    };
  };
  

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true); // Set disabled state to true

    // Check if any required input field is empty
    if (
      !formData.title ||
      !formData.bathrooms ||
      !formData.bedrooms ||
      !formData.built ||
      !formData.notes ||
      !formData.price ||
      !formData.cooling ||
      !formData.description ||
      !formData.houseType ||
      !formData.lot ||
      !formData.sqft ||
      !formData.stories ||
      !formData.type ||
      !formData.location.number ||
      !formData.location.street ||
      !formData.location.state ||
      !formData.location.city ||
      !formData.location.country ||
      !formData.location.zip_or_postcode
    ) {
      toast.error('Please fill in all details', { position: toast.POSITION.TOP_RIGHT });
      setIsSubmitting(false); // Reset disabled state
      return; // Exit function early
    }
      if (type === 'edit') {
        const updatePropertyData = {
          title: formData.title,
          bathrooms: Number(formData.bathrooms), // Convert to number
          bedrooms: Number(formData.bedrooms), // Convert to number
          built: Number(formData.built), // Convert to number
          notes: formData.notes,
          price: Number(formData.price), // Convert to number
          cooling: formData.cooling,
          description: formData.description,
          houseType: formData.houseType,
          lot: formData.lot,
          sqft: Number(formData.sqft), // Convert to number
          stories: Number(formData.stories), // Convert to number
          type: formData.type,
          location: {
              number: formData.location.number,
              street: formData.location.street,
              state: formData.location.state,
              city: formData.location.city,
              country: formData.location.country,
              zip_or_postcode: formData.location.zip_or_postcod}
        };
  
        await apiService.editproperty(updatePropertyData ,userData);
        console.log('User updated successfully');
        onClose();
  
        toast.success('User edited successfully', { position: toast.POSITION.TOP_RIGHT });
        queryClient.invalidateQueries('');
  
      } else if (type === 'add') {
        // Call the addUser API
        // const newPropertyData = {
        //     // Add all the form data
        //     ...formData,
            
        //   };

        const newPropertyData = {
          title: formData.title,
          bathrooms: Number(formData.bathrooms), // Convert to number
          bedrooms: Number(formData.bedrooms), // Convert to number
          built: Number(formData.built), // Convert to number
          notes: formData.notes,
          price: Number(formData.price), // Convert to number
          cooling: formData.cooling,
          description: formData.description,
          houseType: formData.houseType,
          lot: formData.lot,
          sqft: Number(formData.sqft), // Convert to number
          stories: Number(formData.stories), // Convert to number
          type: formData.type,
          location: {
              number: formData.location.number,
              street: formData.location.street,
              state: formData.location.state,
              city: formData.location.city,
              country: formData.location.country,
              zip_or_postcode: formData.location.zip_or_postcode  , // Convert to number
          },
      };
      


        await apiService.addProperty(newPropertyData);
        onClose();
        setIsSubmitting(false); // Set disabled state to true

        queryClient.invalidateQueries('');
  
      }
  
      // Close the modal or perform other actions
      onClose();
    } catch (error) {
      setIsSubmitting(false); // Set disabled state to true

      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        const status = error.response.status;
        const data = error.response.data;
  
        if (status === 400) {
          toast.error(` ${data.message}`, { position: toast.POSITION.TOP_RIGHT });
        } else if (status === 500) {
          toast.error(`Internal Server Error: ${data.message}`, { position: toast.POSITION.TOP_RIGHT });
        } else {
          toast.error(`Unexpected Error: ${data.message || 'Error updating user'}`, { position: toast.POSITION.TOP_RIGHT });
        }
      } else if (error.request) {
        // The request was made but no response was received
        toast.error('No response received from the server', { position: toast.POSITION.TOP_RIGHT });
      } else {
        // Something happened in setting up the request that triggered an Error
        toast.error(`Error: ${error.message}`, { position: toast.POSITION.TOP_RIGHT });
      }
  
      console.error('Error updating user:', error);
    }
  };
 


// Call getPlacesFromAPI when the user submits the search
const handleSearchSubmit = async () => {
  try {
    const placesData = await apiService.fetchLocationAutocomplete(searchQuery);
    console.log('Places data:', placesData);
    // Handle the placesData as needed in your component
  } catch (error) {
    console.error('Error fetching places:', error);
    // Handle errors as needed
  }
};















const [searchQuery, setSearchQuery] = useState('');
const [searchResults, setSearchResults] = useState([]);
const [loading, setLoading] = useState(false);

const handleSearchInputChange = (e) => {
  setSearchQuery(e.target.value);
};

useEffect(() => {
  const delaySearch = setTimeout(() => {
    if (searchQuery.trim() !== '') {
      // Call the API when the user stops typing
      fetchPlacesFromAPI();
    } else {
      // Clear search results if the query is empty
      setSearchResults([]);
    }
  }, 500); // Adjust the delay as needed (e.g., 500 milliseconds)

  // Cleanup the timeout to avoid unnecessary API calls
  return () => clearTimeout(delaySearch);
}, [searchQuery]);

const fetchPlacesFromAPI = async () => {
  try {
    setLoading(true);
    const placesData = await apiService.fetchLocationAutocomplete(searchQuery);
    setSearchResults(placesData);
  } catch (error) {
    console.error('Error fetching places:', error);
    // Handle errors as needed
  } finally {
    setLoading(false);
  }
};


const handleAddressClick = (selectedAddress) => {
  // Update the formData state with the selected address details
  setFormData((prevFormData) => ({
    ...prevFormData,
    location: {
      number: selectedAddress.terms[0]?.value || '',
      street: selectedAddress.terms[1]?.value || '',
      state: selectedAddress.terms[3]?.value || '',
      city: selectedAddress.terms[2]?.value || '',
      country: selectedAddress.terms[4]?.value || '',
      zip_or_postcode: selectedAddress.zipCode || '',
    },
  }));
  setSearchQuery('');
  setSearchResults([]);
}


  return (
    
    <div className="     bg-white rounded-md    "   >
      <h1 className="text-center text-2xl font-semibold pb-4">{type === 'edit' ? 'Edit property' : 'Add property'}</h1>
      <div className=' overflow-y-auto max-h-screen pr-3  mobile:ml-2 mobile:pr-3 '  style={{ maxHeight: "calc(100vh - 285px)" }} > 
      <div className="grid grid-cols-2 gap-4">
        {/* Title */}
        <div className="relative mb-1 mt-8">
  <input
    type="text"
    placeholder=""
    id="title"
    name="title"
    value={formData.title}  
    onChange={handleChange}
    className={`block w-full  px-2.5 pb-2.5 pt-4 text-sm text-gray-900 bg-transparent rounded-lg border-2 border-solid border-gray-700 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer`}
  />
  <label
    htmlFor="title"
    className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-2"
  >
    Title
  </label>
</div>


<div className="mt-2">
  <label htmlFor="cooling" className="block text-sm font-medium text-gray-700">
    Cooling
  </label>
  <select
    id="cooling"
    name="cooling"
    value={formData.cooling}
    onChange={handleChange}
    className="mt-1 p-2 h-12 w-full border border-black rounded-md"
  >
    <option value="None">None</option>
    <option value="Central Air Conditioner">Central Air Conditioner</option>
    <option value="Room Air Conditioner">Room Air Conditioner</option>
    <option value="Evaporative Coolers">Evaporative Coolers</option>
    <option value="Forced Air">Forced Air</option>
    <option value="Ductless Mini-Split Air Conditioner">Ductless Mini-Split Air Conditioner</option>
  </select>
</div>



        {/* Bathrooms */}
        <div className="relative ">
  <input
    type="number"
    placeholder="Bathrooms"
    id="bathrooms"
    name="bathrooms"
    value={formData.bathrooms}
    onChange={handleChange}
    className={`block w-full  px-2.5 pb-2.5 pt-4 text-sm text-gray-900 bg-transparent rounded-lg border-2 border-solid border-gray-700 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer`}
  />
  <label
    htmlFor="bathrooms"
    className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-2"
  >
    Bathrooms
  </label>
</div>


        {/* Bedrooms */}
        <div className="relative mb-1">
  <input
    type="number"
    placeholder="Bedrooms"
    id="bedrooms"
    name="bedrooms"
    value={formData.bedrooms}
    onChange={handleChange}
    className={`block w-full  px-2.5 pb-2.5 pt-4 text-sm text-gray-900 bg-transparent rounded-lg border-2 border-solid border-gray-700 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer`}
  />
  <label
    htmlFor="bedrooms"
    className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-2"
  >
    Bedrooms
  </label>
</div>


        

    

        {/* Price */}
        <div className="relative mb-1 ">
  <input
    type="number"
    placeholder="Price"
    id="price"
    name="price"
    value={formData.price}
    onChange={handleChange}
    className={`block w-full  px-2.5 pb-2.5 pt-4 text-sm text-gray-900 bg-transparent rounded-lg border-2 border-solid border-gray-700 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer`}
  />
  <label
    htmlFor="price"
    className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-2"
  >
    Price
  </label>
</div>


{/* Built */}
<div className="relative mb-1 ">
  <input
    type="number"
    placeholder=""
    id="built"
    name="built"
    value={formData.built}
    onChange={handleChange}
    className={`block w-full px-2.5 pb-2.5 pt-4 text-sm text-gray-900 bg-transparent rounded-lg border-2 border-solid border-gray-700 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer`}
  />
  <label
    htmlFor="built"
    className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-2"
  >
    Year Built
  </label>
</div>




        {/* Description */}
        <div className="relative mb-1 col-span-2">
  <input
    type="text"
    id="description"
    name="description"
    placeholder=''
    value={formData.description}
    onChange={handleChange}
    className={`block w-full  px-2.5 pb-2.5 pt-4 text-sm text-gray-900 bg-transparent rounded-lg border-2 border-solid border-gray-700 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer`}
  />
  <label
    htmlFor="description"
    className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-2"
  >
    Description
  </label>
</div>


    {/* Notes */}
    <div className="relative mb-1 col-span-2">
  <textarea
    id="notes"
    name="notes"
    placeholder=''
    value={formData.notes}
    onChange={handleChange}
    className={`block w-full  px-2.5 py-2.5 text-sm text-gray-900 bg-transparent rounded-lg border-2 border-solid border-gray-700 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer`}
  />
  <label
    htmlFor="notes"
    className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-2"
  >
    Notes
  </label>
</div>


       
<div className="mb-1">
  <label htmlFor="houseType" className="block text-sm font-medium text-gray-700">
    House Type
  </label>
  <select
    id="houseType"
    name="houseType"
    value={formData.houseType}
    onChange={handleChange}
    className="mt-1 p-2 w-full border border-black rounded-md"
  >
    <option value="Single-Family Home">Single-Family Home</option>
    <option value="Condominium">Condominium</option>
    <option value="Planned Unit Development (PUD)">Planned Unit Development (PUD)</option>
    <option value="Duplex">Duplex</option>
    <option value="Triplex">Triplex</option>
    <option value="Fourplex">Fourplex</option>
    <option value="Land">Land</option>
  </select>
</div>


   {/* Type (Buy or Rent) */}
   <div className="mb-1 ">
   <label htmlFor="type" className="block text-sm font-medium text-gray-700">
   Type
          </label>
          <select
            placeholder="Type"
            id="type"
            name="type"
            value={formData.type}
            onChange={handleChange}
            className="mt-1 p-2 w-full border border-black rounded-md"
          >
            <option value="buy">Buy</option>
            <option value="rent">Rent</option>
          </select>
        </div>
        {/* Lot */}
        <div className="relative mb-1 col-span-2">
  <input
    type="text"
    id="lot"
    placeholder=''
    name="lot"
    value={formData.lot}
    onChange={handleChange}
    className={`block w-full  px-2.5 pb-2.5 pt-4 text-sm text-gray-900 bg-transparent rounded-lg border-2 border-solid border-gray-700 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer`}
  />
  <label
    htmlFor="lot"
    className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-2"
  >
    Lot
  </label>
</div>

        {/* Square Feet (sqft) */}
        <div className="relative mb-1">
  <input
    type="number"
    placeholder="Square Feet (sqft)"
    id="sqft"
    name="sqft"
    value={formData.sqft}
    onChange={handleChange}
    className={`block w-full  px-2.5 pb-2.5 pt-4 text-sm text-gray-900 bg-transparent rounded-lg border-2 border-solid border-gray-700 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer`}
  />
  <label
    htmlFor="sqft"
    className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-2" 
  >
    Square Feet (sqft)
  </label>
</div>


        {/* Stories */}
        <div className="relative mb-1">
  <input
    type="number"
    placeholder="Stories"
    id="stories"
    name="stories"
    value={formData.stories}
    onChange={handleChange}
    className={`block w-full  px-2.5 pb-2.5 pt-4 text-sm text-gray-900 bg-transparent rounded-lg border-2 border-solid border-gray-700 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer`}
  />
  <label
    htmlFor="stories"
    className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-2"
  >
    Stories
  </label>
</div>


     

        {/* Location */}
        <div className="mb-1 col-span-2">
        
          <div className="grid grid-cols-2 gap-2">
            
           

<div className="mb-1 col-span-2">
<label className="block text-m font-bold text-gray-900" >Location</label>
<div>
      <input
        type="text"
        placeholder="Search places..."
        value={searchQuery}
        onChange={handleSearchInputChange}
        className="mt-1 p-2 w-full border-2  border-black rounded-lg"
      />
      {loading && <p>Loading...</p>}
      {!loading && searchResults?.length > 0 && (
        <ul className="max-h-48 overflow-y-auto bg-gray-200 border border-gray-300 rounded-md p-2" style={{ maxHeight: "175px" }}>
          {searchResults?.map((result) => (
            <li  className="py-1 border-b border-black"  key={result?.place_id}               onClick={() => handleAddressClick(result)}
 >{result?.description}</li>
          ))}
        </ul>
      )}
    </div>
</div>


<div className="relative mb-1">
  <input
    type="text"
    id="number"
    name="location.number"
    value={formData.location.number}
    onChange={handleChange}
    className={`block w-full  px-2.5 pb-2.5 pt-4 text-sm text-gray-900 bg-transparent rounded-lg border-2 border-solid border-gray-700 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer`}
    placeholder=" "
  />
  <label
    htmlFor="number"
    className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-2"
  >
    Number
  </label>
</div>


<div className="relative mb-1">
  <input
    type="text"
    id="street"
    name="location.street"
    value={formData.location.street}
    onChange={handleChange}
    className={`block w-full  px-2.5 pb-2.5 pt-4 text-sm text-gray-900 bg-transparent rounded-lg border-2 border-solid border-gray-700 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer`}
    placeholder=" "
  />
  <label
    htmlFor="street"
    className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-2"
  >
    Street
  </label>
</div>

<div className="relative mb-1">
  <input
    type="text"
    id="state"
    name="location.state"
    value={formData.location.state}
    onChange={handleChange}
    className={`block w-full  px-2.5 pb-2.5 pt-4 text-sm text-gray-900 bg-transparent rounded-lg border-2 border-solid border-gray-700 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer`}
    placeholder=" "
  />
  <label
    htmlFor="state"
    className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-2"
  >
    State
  </label>
</div>

<div className="relative mb-1">
  <input
    type="text"
    id="city"
    name="location.city"
    value={formData.location.city}
    onChange={handleChange}
    className={`block w-full  px-2.5 pb-2.5 pt-4 text-sm text-gray-900 bg-transparent rounded-lg border-2 border-solid border-gray-700 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer`}
    placeholder=" "
  />
  <label
    htmlFor="city"
    className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-2"
  >
    City
  </label>
</div>

<div className="relative mb-8">
  <input
    type="text"
    id="country"
    name="location.country"
    value={formData.location.country}
    onChange={handleChange}
    className={`block w-full  px-2.5 pb-2.5 pt-4 text-sm text-gray-900 bg-transparent rounded-lg border-2 border-solid border-gray-700 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer`}
    placeholder=" "
  />
  <label
    htmlFor="country"
    className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-2"
  >
    Country
  </label>
</div>

{/* <div className="mb-1">
  <label htmlFor="zip_or_postcode" className="block text-sm font-medium text-gray-700">Zip or Postcode</label>
  <input
    type="text"
    id="zip_or_postcode"
    name="location.zip_or_postcode"
    value={formData.location.zip_or_postcode}
    onChange={handleChange}
    className="mt-1 p-2 w-full border border-black rounded-md"
  />
</div> */}

<div className="relative mb-1">
  <input
    type="text"
    id="zip_or_postcode"
    name="location.zip_or_postcode"
    value={formData.location.zip_or_postcode}
    onChange={handleChange}
    className={`block w-full  px-2.5 pb-2.5 pt-4 text-sm text-gray-900 bg-transparent rounded-lg border-2 border-solid border-gray-700 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer`}
    placeholder=" "
  />
  <label
    htmlFor="zip_or_postcode"
    className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-2"
  >
    Zip or Postcode
  </label>
</div>



          </div>
        </div>
      </div>
</div>
      {/* Submit Button */}
      <button className="bg-black text-white p-4 rounded-md hover:bg-black w-full mt-4" type="submit" onClick={handleSubmit}>
          Add Property
        </button>
    </div>
  );
};