
import React, { useEffect, useState } from "react";
import login_right_img from '../../assets/images/login-new.png';
import login_logo from '../../assets/images/login-logo.svg';
import AuthService from '../../services/authservice/authservice';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { loginValidationSchema } from '../../utils/validation';
import eye_icon from '../../assets/images/eye.svg'
import cross_eye_icon from '../../assets/images/Eye-closed.svg'
import { useMutation } from "@tanstack/react-query";
import Modal from "../common/modal/modal";
import { ForgotModal } from "../common/modal/ForgotModal/ForgotModal";
import Cookies from 'js-cookie';
import { TextField } from "@mui/material";



const Login = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    showPassword: false,
    isSubmitting: false,
    modalforgotpassword: false,
    initialValues: {
      email: Cookies.get('UserEmail') || '',
      password: Cookies.get('UserPassword') || '',
      rememberMe: Cookies.get('RememberMe') === 'true',
    }
  });

  useEffect(() => {
    const initialValues = {
      email: Cookies.get('UserEmail') || '',
      password: Cookies.get('UserPassword') || '',
      rememberMe: Cookies.get('RememberMe') === 'true',
    };
    setFormData(prevState => ({ ...prevState, initialValues }));
  }, []);

  const handleLogin = async (values) => {
    try {
      setFormData(prevState => ({ ...prevState, isSubmitting: true }));

      const response = await AuthService.login(values.email, values.password);
      localStorage.setItem('Authtoken', response.accessToken);

      if (values.rememberMe) {
        const daysUntilExpiration = 1000;
        const expirationDate = new Date();
        expirationDate.setTime(expirationDate.getTime() + (daysUntilExpiration * 24 * 60 * 60 * 1000));
        Cookies.set('RememberMe', 'true', { expires: expirationDate });
        Cookies.set('UserEmail', values.email, { expires: expirationDate });
        Cookies.set('UserPassword', values.password, { expires: expirationDate });
      } else {
        Cookies.remove('RememberMe');
        Cookies.remove('UserEmail');
        Cookies.remove('UserPassword');
      }

      toast.success('Successfully logged in');
      setFormData(prevState => ({ ...prevState, isSubmitting: false }));
      if (response) {
        navigate('/dashboard');
      }
    } catch (error) {
      toast.error('Email or Password is incorrect');
      setFormData(prevState => ({ ...prevState, isSubmitting: false }));
    }
  };

  const mutation = useMutation({ mutationFn: handleLogin });

  const togglePasswordVisibility = () => {
    setFormData(prevState => ({ ...prevState, showPassword: !prevState.showPassword }));
  };

  const openForgotPassModal = () => setFormData(prevState => ({ ...prevState, modalforgotpassword: true }));
  const closeForgotPassModal = () => setFormData(prevState => ({ ...prevState, modalforgotpassword: false }));

  return (
    <>
      <Modal
        isOpen={formData.modalforgotpassword}
        onClose={closeForgotPassModal}
        title="Edit User"
        content={<ForgotModal type={'add'} onClose={closeForgotPassModal} />}
      />

      <div className="flex flex-col sm:flex-row h-screen">
        <div className="sm:w-1/2 h-1/3 sm:h-full overflow-hidden relative">
          <img
            className="w-full h-full align-top "
            src={login_right_img}
            alt="Background Image"
          />
          <img
            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-1/5"
            src={login_logo}
            alt="Overlay Image"
          />
        </div>

        <Formik
          initialValues={formData.initialValues}
          validationSchema={loginValidationSchema}
          onSubmit={(values) => mutation.mutate(values)}
        >
          {({ errors, touched, handleChange, handleBlur, values  }) => (
            <Form className="sm:w-1/2 flex justify-center items-center">
              <div className="w-full xl:w-2/3 p-8 rounded-lg flex flex-col text-center">
                <h1 className="text-4xl mb-4 font-bold text-left">Login</h1>
                <p className="text-lg text-gray-700 mb-8 text-left">
                  Gain entry to the administrative interface by logging in
                </p>

                {/* <Field
                  type="email"
                  name="email"
                  className={`w-full h-16 px-4 mb-4 rounded-lg border-2 ${
                    errors.email && touched.email ? 'border-red-500' : 'border-black'
                  }`}
                  placeholder="Email"
                /> */}

                {/* <TextField
                  id="email"
                  name="email"
                  type="email"
                  label="Email"
                  variant="outlined"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`w-full h-16 px-4 mb-4 rounded-lg border-2 ${errors.email && touched.email ? 'border-red-500' : 'border-black'}`}
                  error={touched.email && Boolean(errors.email)}
                  // helperText={touched.email && errors.email}
                /> */}

                <TextField
  id="email"
  name="email"
  type="email"
  label="Email"
  variant="outlined"
  value={values.email}
  onChange={handleChange}
  onBlur={handleBlur}
  className="w-full h-16 px-4 mb-4 rounded-lg"
  error={touched.email && Boolean(errors.email)}
  sx={{
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'black',
        borderWidth: '2px', // Increased border width on hover

      },
      '&:hover fieldset': {
        borderColor: 'black',
        borderWidth: '2px', // Increased border width on hover

      },
      '&.Mui-focused fieldset': {
        borderColor: 'black',
        borderWidth: '2px', // Increased border width on hover

      },
    },
  }}
/>

                
                <ErrorMessage name="email" component="div" className="text-red-500 text-left  mb-2"  />

                <div className="relative mt-2 "  >
                  {/* <Field
                    type={formData.showPassword ? 'text' : 'password'}
                    name="password"
                    className={`w-full h-16 px-4 mb-4 rounded-lg border-2 ${
                      errors.password && touched.password ? 'border-red-500' : 'border-black'
                    }`}
                    placeholder="Password"
                  /> */}
                <TextField
                    id="password"
                    name="password"
                    type={formData.showPassword ? 'text' : 'password'}
                    label="Password"
                    variant="outlined"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={`w-full h-16 px-4 mb-4  rounded-lg border-2 ${errors.password && touched.password ? 'border-red-500' : 'border-black'}`}
                    error={touched.password && Boolean(errors.password)}
                    sx={{
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'black',
        borderWidth: '2px', // Increased border width on hover

      },
      '&:hover fieldset': {
        borderColor: 'black',
        borderWidth: '2px', // Increased border width on hover

      },
      '&.Mui-focused fieldset': {
        borderColor: 'black',
        borderWidth: '2px', // Increased border width on hover

      },
    },
  }}
                    // helperText={touched.password && errors.password}
                  />

                  <span
                    className="show-password absolute mt-8 right-4 transform -translate-y-1/2 cursor-pointer"
                    onClick={togglePasswordVisibility}
                  >
                    <img
                      src={formData.showPassword ? cross_eye_icon : eye_icon}
                      alt={formData.showPassword ? 'Hide Password' : 'Show Password'}
                      style={{ color: 'black' }}
                    />
                  </span>
                </div>
                <ErrorMessage name="password" component="div" className="text-red-500 text-left" />

                <div className="flex items-center justify-between mb-4">
                  <div className="flex items-center">
                    <Field type="checkbox" name="rememberMe" id="rememberMe" className="mr-2" />
                    <label htmlFor="rememberMe" className="text-sm text-gray-600">Remember me</label>
                  </div>

                  <p className="text-sm text-gray-600 cursor-pointer">
                    <a onClick={openForgotPassModal}>Forgot password?</a>
                  </p>
                </div>

                <button
                  type="submit"
                  className="w-full h-16 px-4 py-3 flex items-center justify-center gap-2 rounded-lg border border-black bg-black text-white"
                  disabled={formData.isSubmitting}
                >
                  {formData.isSubmitting ? 'Logging in...' : 'Login'}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );

};

export default Login;



