import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from '../components/auth/login';
import ManageUsers from '../components/layout/manage-user/ManageUsers';
import ManagePosts from '../components/layout/manage-posts/ManagePosts';
import ManageProperties from '../components/layout/manage-properties/ManageProperties';
import SupportRequests from '../components/layout/supports-requests/SupportRequests';
import NFTOwners from '../components/layout/nft-owners/nft-owner';
import ReportRequests from '../components/layout/report requests/ReportRequests';
import Dashboard from '../components/layout/dashboard/dashboard';
import SupportRequestView from '../components/layout/supports-requests/support-requestsView/supportRequestView';
import PostView from '../components/layout/manage-posts/PostView/PostView';
import { AuthProvider } from '../context/authContext';
import PrivateRoute from '../utils/privateroute';

const AppRoutes= () => {
  return (
    <AuthProvider>

<Router>
    <Routes>
    <Route path="/dashboard" element={<PrivateRoute component={Dashboard} />} />

<Route path="/" element={<Login />} />

 <Route path="/manage-users" element={<PrivateRoute component={ManageUsers} />} />
<Route path="/manage-posts" element={<PrivateRoute component={ManagePosts} />} />
<Route path="/manage-properties" element={<PrivateRoute component={ManageProperties} />} />
<Route path="/support-requests" element={<PrivateRoute component={SupportRequests} />} />
<Route path="/nft-owners" element={<PrivateRoute component={NFTOwners} />} />

<Route path="/report-request" element={<PrivateRoute component={ReportRequests} />} />

<Route path="/manage-posts/postview/:postId" element={<PrivateRoute component={PostView} />} />
<Route path="/support-requests/support-request-view/:requestId" element={<PrivateRoute component={SupportRequestView} />} />


    </Routes>
</Router>
    </AuthProvider>

    )
}

export default AppRoutes;