import React ,{useEffect, useState} from 'react'
import { Header } from '../../common/header/header'
import Sidebar from '../../common/sidebar/sidebar'
import apiService from '../../../services/apiservice'
import editicon from '../../../assets/images/edit_icon.svg';
import Deleteicon from '../../../assets/images/delete_icon.svg';
import check_dark from '../../../assets/images/check-black.svg'
import check_light from '../../../assets/images/check-grey.svg'
import property_img from '../../../assets/images/propert-img2.png'
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import prev_button from '../../../assets/images/prevbutton.svg';
import next_button from '../../../assets/images/nextbutton.svg';
import Modal from '../../common/modal/modal';
import { PropertiesModal } from '../../common/modal/managePropertiesModal/PropertiesModal';
import { DeleteModal } from '../../common/modal/deleteModal/deleteModal';
import Loader from '../../common/loader/loader';
import useAuthentication from '../../../utils/authHook';



const ManageProperties = () => {
  useAuthentication()
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(10);
  const [modalAddUserOpen, setmodalAddUserOpen] = useState(false);
  const [modalEditUserOpen, setmodalEditUserOpen] = useState(false);
  const [selectedPropertyForEdit, setselectedPropertyForEdit] = useState(null);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [selectedPropertyForDelete, setselectedPropertyForDelete] = useState(null);
  const [loading, setLoading] = useState(true); // New state variable for loading indicator




  const openAdduser = () => setmodalAddUserOpen(true);
  const closeAdduser = () => setmodalAddUserOpen(false)
  const closeEdituser = () => setmodalEditUserOpen(false)

  const openEditUser = (user) => {
    // Set other state or perform other actions related to editing
    setselectedPropertyForEdit(user);

    setmodalEditUserOpen(true);
  };


  const openDeleteConfirmation = (user) => {
    setselectedPropertyForDelete(user);
    setDeleteConfirmationOpen(true);
  };
  
  const closeDeleteConfirmation = () => {
    setselectedPropertyForDelete(null);
    setDeleteConfirmationOpen(false);
  };



  const fetch_manage_properties = async () => {
    try {
      setLoading(true); 

      const data = await apiService.get_manage_properties(skip, take);
      setTotalPages(Math.ceil(data.totalCount / take));
      // setTotalPages(15);

      return data
    } catch (error) {
      // Handle error (display an error message or log it)
    }finally {
      setLoading(false); // Set loading to false once data is fetched
    }
  };
  const query = useQuery({ queryKey: ['manageproperties'], queryFn: fetch_manage_properties, })



  useEffect(() => {
    query.refetch();
  }, [skip]);
  

  const handleDashboardClick = () => {
    if(isSidebarOpen){
      setIsSidebarOpen(!isSidebarOpen);
    }
  };
  

  const Pagination = ({ currentPage, setCurrentPage, totalPages }) => {


    const goToPage = (page) => {
      console.log('page value111,',page, 'here si take val',take)
  
      setCurrentPage(page);
  //     setSkip((page*10)-10)
  //     console.log('skip value from gotopage',skip)
  // // fetch_manage_posts()
  //     queryClient.invalidateQueries('manageposts');/
  console.log((page - 1) * take);
  setSkip((page - 1) * take);
  console.log('afterupdate,',skip, 'here si take val',take)
  query.refetch();
    };
  
    const handlePrevClick = () => {
      if (currentPage > 1) {
        goToPage(currentPage - 1);
      }
    };
  
    const handleNextClick = () => {
      if (currentPage < totalPages) {
        goToPage(currentPage + 1);
      }
    };
    const generatePageNumbers = () => {
      const pageNumbers = [];
      const maxPageButtons = 3; // Adjust this value to change the number of displayed pagess
      let startPage = Math.max(1, currentPage - Math.floor(maxPageButtons / 2));
      let endPage = Math.min(totalPages, startPage + maxPageButtons - 1);
      if (endPage - startPage + 1 < maxPageButtons) {
        startPage = Math.max(1, endPage - maxPageButtons + 1);
      }
      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }
      return pageNumbers;
    };
    return (
      <div className="flex items-center justify-center pt-15 pb-19 mt-5 mb-3 pagination">
        <ul className="flex list-none">
          <li
            className= {`w-12 h-12 flex items-center justify-center cursor-pointer ${currentPage === 1 ? 'opacity-30 cursor-not-allowed':'' } `}
            onClick={handlePrevClick}
          >
            <img src={prev_button} alt="Previous Page" />
          </li>
          {generatePageNumbers().map((page) => (
            <li
              key={page}
              className={`ml-2 w-12 h-12 border-2 border-solid border-gray-300 rounded-full text-gray-500 text-lg flex items-center justify-center cursor-pointer ${
                page === currentPage ? 'bg-black shadow-md border-none text-white' : ''
              }`}
              onClick={() => goToPage(page)}
            >
              {page}
            </li>
          ))}
          <li
            className={`ml-2 w-12 h-12 flex items-center justify-center cursor-pointer ${currentPage === totalPages ? 'opacity-30 cursor-not-allowed':''} `}
            onClick={handleNextClick}
          >
            <img src={next_button} alt="Next Page" />
          </li>
        </ul>
      </div>
    );
  };
  
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };



  return (
    <div className="flex flex-col">
    <Header toggleSidebar={toggleSidebar} />
    <div className="flex flex-grow">
      <Sidebar isSidebarOpen={isSidebarOpen} />
      <div className="flex flex-col flex-grow overflow-auto">

      {loading ? (
      <Loader />
    ) : (

      <div className="HomeMainDiv   "   onClick={handleDashboardClick} >
        {/* <div className="RightSideDiv pl-80 pr-5"> */}
        <div className=' pt-6  pl-5 lg:pl-72 pr-5'>

        
        <Modal
    isOpen={modalAddUserOpen}
    onClose={closeAdduser}
    title="Add Property"
    content={<PropertiesModal  type={'add'} onClose={closeAdduser} />} // Adjust content as needed
  />
   <Modal
    isOpen={modalEditUserOpen}
    onClose={closeEdituser}
    title="edit Property"
    content={<PropertiesModal  type={'edit'} userData={selectedPropertyForEdit} onClose={closeEdituser} />} // Adjust content as needed
  />
 <Modal
  isOpen={deleteConfirmationOpen}
  onClose={closeDeleteConfirmation}
  title="Delete User"
  
  content={
    <DeleteModal  type={'Manage-properties'}  Data={selectedPropertyForDelete} onClose={closeDeleteConfirmation} />
  }
/>
    <div className="right-side-inner posts">
      <div className="user-top mb-6">
        <h3 className="text-xl font-semibold inline-block">Manage Properties</h3>
          <div className="post-select float-right">
             {/* filter code is commented below */}
            {/* <select className="post-select-option bg-white border border-blue-200 rounded-md w-40 h-10 mr-4 px-4">
              <option>All</option>
            </select> */}
            <button className="bg-black text-white text-sm py-2 px-4 float-right rounded-md" onClick={openAdduser}>
              Add Property
            </button>
          </div>
      </div>
      <div className="table-main bg-white rounded-lg  overflow-y-auto  mobile:max-h-[calc(100vh-320px)] max-h-[calc(100vh-265px)]    " >
        <table className="table w-full block">
          <thead className='text-left'> 
            <tr>
              <th scope="col" className='p-4 font-medium' >Image</th>
              <th scope="col" className='p-4 font-medium'  >Property Name</th>
              {/* <th scope="col" className='p-4 font-medium'  >Status</th> */}
              <th scope="col" className='p-4 font-medium'   >Address</th>
              <th scope="col" className='p-4 font-medium'  >Action</th>
            </tr>
          </thead>
          <tbody>
          {query?.data?.house?.map((property,index) => (
                      <tr key={property.id} className={` ${index % 2 === 0 ? 'bg-custom-gray' : ''}`}>
                      <td className="p-4 text-sm">
              <span className="user-img block xl:w-28 xl:h-16  bg-gray-300 rounded-md overflow-hidden sm:w-16 sm:h-10 mobile-image">
                  <img src={property.streetViewUrl} alt="" className="w-full h-full object-cover  " />
                </span>
              </td>
              <td className="p-4 text-sm">{property.title || ' Title here'}</td>

            
              <td className="p-4 text-sm ">
              {`${property.location.number} ${property.location.street}, ${property.location.city}, ${property.location.state}, ${property.location.country}`}
            </td>            
              {/* <td className="p-4"> */}
              <td className="p-4 text-sm flex items-center mt-4">

                <button className="w-8 h-8 bg-black text-white rounded-full" onClick={() => openEditUser(property)} >
                  <img src={editicon} alt="Edit Icon" />
                </button>
                <button className="w-8 h-8 bg-red-500 text-white rounded-full ml-2" onClick={() => openDeleteConfirmation(property.id)}>
                  <img src={Deleteicon} alt="Delete Icon" />
                </button>
              </td>
            </tr>
            ))}

          </tbody>
        </table>
      </div>
      {/* <Pagination  /> */}
      <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages} />

    </div>

          
        </div>
      </div>
    )}
</div>
</div>
</div>
  )
}

export default ManageProperties