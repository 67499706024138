import React, { useEffect, useState } from 'react'
import Sidebar from '../../../common/sidebar/sidebar'
import { Header } from '../../../common/header/header'
import profile_img from '../../../../assets/images/postview.png'
import location_icon from '../../../../assets/images/loca.svg'
import apiService from '../../../../services/apiservice'
import { useNavigate, useParams } from 'react-router-dom';
import back_button from '../../../../assets/images/Caret left.svg'



const PostView = () => {

const Navigate = useNavigate()
    const [postData, setpostData] = useState(null);
    const { postId } = useParams();
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleSidebar = () => {
      setIsSidebarOpen(!isSidebarOpen);
    };

    useEffect(() => {

    const supportRequestDetails  = async () => {
        try {
          const Data = await apiService.get_Post_details(postId);
          setpostData(Data); 
        } catch (error) {
        }
      };
  
      supportRequestDetails ();
  
}, [postId]);

const cancelfunction=()=>{
  Navigate(`/manage-posts`);  }


  return (
    <div className="flex flex-col ">
    <Header toggleSidebar={toggleSidebar} />
    <div className=" flex flex-grow">

    <Sidebar isSidebarOpen={isSidebarOpen} />


    <div className="flex flex-col flex-grow overflow-auto">
    
    <div className="HomeMainDiv">
    <div className="RightSideDiv sm:pl-4 lg:pl-80 pr-5">
            <div className='flex items-center mr-4'>
            <img src={back_button} alt='back button' onClick={cancelfunction} className='mt-5 mr-6  cursor-pointer' />

            <h3 className="text-2xl font-semibold inline-block  mt-8 mb-4"> post view</h3>
            </div>

            <div className="right-side-inner posts">
  <div className="user-top flex items-center">
    {/* <h3 className="text-2xl font-semibold text-gray-700">Post</h3> */}
    {/* <div className="post-select ml-auto">
      <button className="bg-blue-500 text-white px-4 py-2 rounded-md font-semibold">Edit</button>
    </div> */}
  </div>
  <div className="table-main request2  flex">
    <div className="post2-left w-1/2 h-78 ml-2 bg-gray-300  overflow-hidden ">
    <img src={postData?.image} alt="" className="w-full h-[400px] object-cover" />
    </div>
    <div className="post2-right w-1/2 pl-8">
      <h3 className="text-2xl font-semibold text-gray-700 mb-4">{postData?.title }</h3>
      <p className="text-base font-normal mb-4">
        {postData?.content||'description of the post'}
      </p>
      <div className="text-base font-normal mb-4">
        <img src={location_icon} alt="" className="mr-2 inline-block" /> {postData?.location || '--'}
      </div>
    </div>
  </div>
</div>



    </div>
    </div>
        </div>
  </div>
  </div>
  )
}

export default PostView