import React, { useState } from 'react'
import apiService from '../../../../services/apiservice';
import { toast,  } from 'react-toastify';
import AuthService from '../../../../services/authservice/authservice';


export const ForgotModal = ({onClose}) => {
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
  
    const validateEmail = () => {
      // Basic email format validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        setEmailError('Enter a valid email address');
        return false;
      }
      setEmailError('');
      return true;
    };
  
    const handleSubmit = async () => {
      try {
        if (!validateEmail()) {
          return;
        }
  
        setIsSubmitting(true);
  
        await AuthService.forgotPassword({ email });
  
        setEmail('');
        setIsSubmitting(false);
  
        toast.success('Password reset instructions sent to your email', { position: toast.POSITION.TOP_RIGHT });
      } catch (error) {
        setIsSubmitting(false);
  
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT });
        console.error('Error:', error);
      }
      onClose()

    };
  
    return (
      <div>
        {/* Your modal structure */}
        <div className="modal-content">
          {/* ... other modal content */}
          <p className="mb-8 text-2xl font-bold ">
        Reset Password
        
      </p>
          {/* Email input field */}
          <div className="mb-8 mt-8">
            <input
              type="email"
              name="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setEmailError('');
              }}
              className={`w-full h-16 px-4 rounded-lg border-2 border-solid border-gray-700 ${
                emailError ? 'border-red-500' : ''
              }`}
              placeholder="Email"
            />
            {emailError && <p className="text-red-500 text-sm">{emailError}</p>}
          </div>
  
          {/* Submit button */}
          <button
            className="flex w-full h-16 px-4 justify-center items-center bg-black text-white rounded-lg cursor-pointer " 
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Submitting...' : 'Submit'}
          </button>
        </div>
      </div>
    );
  };