
import React, { useEffect, useState } from 'react';
import burgermenu from '../../../assets/images/burger-menu.svg'
import config from '../../../config';




export  const Header = ({toggleSidebar}) => {
  const [userData, setUserData] = useState({ name: '', email: '' });

  useEffect(() => {
    const storedUserData = JSON.parse(localStorage.getItem('userData'));
    console.log('Data stored in local storage: bbebebe', storedUserData);

    if (storedUserData) {
      setUserData(storedUserData);
    }
  }, []);

  console.log('Data stored in local storage:', userData);

  return (
    <div className="RightSideDiv flex-grow">
      <div className="header-right bg-white p-4 sm:p-1  float-left w-full relative z-10">
        <div className="header-right-side flex items-center justify-between">
          <button
            onClick={toggleSidebar}
            className="lg:hidden p-2 bg-white rounded-md"
          >
            <img src={burgermenu} alt="Hamburger Icon" className="w-8 h-8" />
          </button>
            <div className="mb-2 mt-2 ml-9  flex items-center">
      <img src={config.imagebaseUrl} alt="Logo" className=' w-10 h-10 md:w-14 md:h-14'  />
      <h2 className="ml-2 font-bold text-xs md:text-base lg:text-2xl ">Obi Admin</h2>
  </div>

          <div className="user-name flex items-center md:justify-end user-details mr-5"> {/* Added md:justify-end for medium and larger screens */}
          <span className="w-10 h-10 sm:w-12 sm:h-12  rounded-full overflow-hidden inline-block">
              <img
                src={config.imagebaseUrl}
                alt="User Avatar"
                className="w-full h-full object-cover"
              />
            </span>
            <div className="user-text inline-block pl-2  ">
            <h4 className="block lg:text-lg sm:text-base text-black m-0 mb-1">OBI Admin</h4>
            <h5 className="block text-xs sm:text-sm text-gray-600 font-normal">
               dreamline.digitalspk@gmail.com
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};