import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function PrivateRoute(props) {
  const navigate = useNavigate();
  const { component: Component, ...rest } = props; // Destructure the component and rest of the props

  useEffect(() => {
    const auth = localStorage.getItem('Authtoken') !== null;

    if (!auth || auth === 'undefined') {
      navigate('/');
    }
  }, [navigate]);

  return <Component {...rest} />; // Render the component with the rest of the props
}

export default PrivateRoute;
